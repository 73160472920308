import httpService from "./httpService";

const baseEndpoint = "/uploadFiles";
const uploadFileEndpoint = "/uploadFile";
const getUploadFilesEndpoint = "/";
const deleteUploadFileEndpoint = "/deleteUploadFile";

const uploadFile = (formData: any) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpService.post(baseEndpoint + uploadFileEndpoint, formData, {
    headers,
  });
};

const getUploadFiles = () => {
  return httpService.get(baseEndpoint + getUploadFilesEndpoint);
};

const deleteUploadFile = (idUploadFile: number) => {
  return httpService.post(
    baseEndpoint + deleteUploadFileEndpoint,
    idUploadFile
  );
};

const exportObject = { uploadFile, getUploadFiles, deleteUploadFile };

export default exportObject;
