import React, { useContext, useEffect, useState } from "react";
import BoxContent from "../common/BoxContent";
import uploadFileService from "../../services/uploadFileService";
import Moment from "react-moment";
import formatBytes from "../../utils/formatBytes";
import { getFullUrl } from "../../services/httpService";
import UserContext from "../../context/userContext";

import DeleteIcon from "../../assets/images/delete.png";

const UploadFiles: React.FC<{ history: any }> = ({ history }) => {
  const user = useContext(UserContext);
  const [uploadFiles, setUploadFiles] = useState([]);

  useEffect(() => {
    const init = async () => {
      await loadUploadFiles();
    };
    init();
  }, []);

  const handleNew = () => {
    // alert("New Item");
    history.push("/admin/upload-file");
  };

  // const handleView = (item: any) => {
  //   window.open(getImageUrl(item), "_blank");
  // };

  const getImageUrl = (item: any) => {
    return getFullUrl(item.url);
  };

  const handleDelete = async (e: any, uploadFile: any) => {
    e.stopPropagation();
    //alert(`"Delete Item ${uploadFile.idUploadFile}"`);
    //alert("Not implemented :) (yet)");
    // if (!user?.IsAdmin) {
    //   return;
    // }
    const { ok } = await uploadFileService.deleteUploadFile(
      uploadFile.idUploadFile
    );
    if (!ok) {
      alert("Error deleting upload file");
      return;
    }
    await loadUploadFiles();
  };

  const loadUploadFiles = async () => {
    const response = await uploadFileService.getUploadFiles();
    if (response.ok) {
      setUploadFiles(response.data as []);
    }
  };

  return (
    <BoxContent id="uploadFiles" containerClassName="" useFullWidth={true}>
      <div className="row">
        <div className="col-sm-6">
          <h1 className="admin-page-title">Upload Files</h1>
        </div>
        <div className="col-sm-6 input-form">
          <div className="container-fluid">
            <div className="row justify-content-end button-new">
              <button
                className="button button-a button-normal button-rouded"
                style={{ width: "auto" }}
                onClick={() => handleNew()}
                disabled={user?.isAdminDemo}
              >
                Add New
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="d-flex justify-content-end">
          <p>{uploadFiles.length} files</p>
        </div>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Type</th>
            <th scope="col">Name</th>
            <th scope="col">Url</th>
            <th scope="col">Size</th>
            <th scope="col">Upload Date</th>
            <th className="table-edit-column" />
            <th className="table-edit-column" />
          </tr>
        </thead>
        <tbody>
          {uploadFiles.map((uploadFile: any) => (
            <tr key={uploadFile.idUploadFile.toString()}>
              <th scope="row">{uploadFile.idUploadFile}</th>
              <td>{uploadFile.mime}</td>
              <td>{uploadFile.fileName}</td>
              <td>
                <a
                  href={getImageUrl(uploadFile)}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="LinkedIn"
                >
                  {getImageUrl(uploadFile)}
                </a>
              </td>
              <td>{formatBytes(uploadFile.size, 2)}</td>
              <td>
                <Moment format="YYYY/MM/DD - HH:mm">
                  {uploadFile.uploadDate}
                </Moment>
              </td>
              <td className="img-edit">
                {/* <FontAwesomeIcon
                  icon={faEye}
                  onClick={() => handleView(uploadFile)}
                /> */}
                {/* <i className="fa fa-eye"></i> */}
              </td>
              <td onClick={(e) => handleDelete(e, uploadFile)}>
                <img
                  className="img-edit"
                  src={DeleteIcon}
                  alt=""
                  onClick={(e) => handleDelete(e, uploadFile)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </BoxContent>
  );
};

export default UploadFiles;
