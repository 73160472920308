import React, { Children, cloneElement, useState } from "react";
import Joi from "joi";

const Form: React.FC<{
  data: any;
  setData: any;
  schema: any;
  errors: any;
  setErrors: any;
  doSubmit: any;
  onSubmitOk: any;
  children: any;
  submitErrorMessage: string;
  submitOkMessage: string;
}> = ({
  data,
  setData,
  schema,
  errors,
  setErrors,
  doSubmit,
  onSubmitOk,
  children,
  submitErrorMessage,
  submitOkMessage,
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [isSubmitOk, setIsSubmitOk] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);

  const validate = () => {
    var options = { abortEarly: false };
    const { error } = Joi.object(schema).validate(data, options);
    if (!error) {
      return undefined;
    }
    const errors: any = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const validateProperty = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }): string | undefined => {
    const obj = { [name]: value }; // [] is computed properties in ES6
    const properySchema = Joi.object({ [name]: schema[name] });
    const { error } = properySchema.validate(obj);
    return error ? error.details[0].message : undefined;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const newErrors = validate();
    setErrors(newErrors || {});
    if (newErrors) return;

    setIsBusy(true);
    setIsSubmitOk(false);
    setIsSubmitError(false);

    const result = await doSubmit();

    setIsBusy(false);
    if (!result) {
      setIsSubmitError(true);
      return;
    }
    setIsSubmitOk(true);

    if (onSubmitOk) {
      onSubmitOk();
    }
  };

  const handleChange = ({ currentTarget: input }: { currentTarget: any }) => {
    const newErrors = { ...errors }; // ... spread operator

    const errorMessage = validateProperty(input);

    if (errorMessage) newErrors[input.name] = errorMessage;
    else delete newErrors[input.name];

    const newData = { ...data };
    if (input.type === "checkbox") {
      newData[input.name] = input.checked;
    } else {
      newData[input.name] = input.value;
    }

    setData(newData);
    setErrors(newErrors);
  };

  return (
    <form onSubmit={handleSubmit} className="input-form">
      <div className="row">
        {Children.map(children, (child) => {
          if (
            child.props.inputType === "FormInput" ||
            child.props.inputType === "FormTextArea" ||
            child.props.inputType === "FormInputCheckbox"
          ) {
            return cloneElement(child, {
              onChange: handleChange,
              value: data[child.props.name],
              checked:
                child.props.inputType === "FormInputCheckbox"
                  ? data[child.props.name]
                  : undefined,
            });
          } else if (child.props.inputType === "FormButton") {
            return cloneElement(child, {
              disabled: validate() || isBusy,
            });
          }
          return child;
        })}
        <div className="col-md-12 text-center mb-3">
          {isBusy && <div className="loading">Please wait...</div>}
          {isSubmitError && (
            <div className="error-message">{submitErrorMessage}</div>
          )}
          {isSubmitOk && submitOkMessage && (
            <div className="submit-ok">{submitOkMessage}</div>
          )}
        </div>
      </div>
    </form>
  );
};

export default Form;
