import React, { useState } from "react";
import uploadFileService from "../../services/uploadFileService";
import BoxContent from "../common/BoxContent";
import FileUpload from "../common/FileUpload";
import _ from "lodash";

function UploadFile(props: any) {
  const [uploadFolderPath, setUploadFolderPath] = useState("/uploads");
  const [uploadFilePath, setUploadFilePath] = useState("");
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);

  const handleUploadFolderPathChange = (event: any) => {
    setUploadFolderPath(event.target.value);
    updateFilePath(event.target.value, selectedFile);
  };

  const handleFileSelected = (file: any) => {
    setSelectedFile(file);
    updateFilePath(uploadFolderPath, file);
  };

  const updateFilePath = (folderPath: any, file: any) => {
    if (file) {
      const folder = _.trim(folderPath, "/");
      if (folder) {
        setUploadFilePath(`/${folder}/${file.name}`);
      } else {
        setUploadFilePath(`/${file.name}`);
      }
    }
  };

  const handleFileUpload = async (file: any) => {
    setIsUploadComplete(false);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadFolderPath", uploadFolderPath);
    var { ok } = await uploadFileService.uploadFile(formData);
    if (!ok) {
      alert("uploadFile error");
      return;
    }
    setIsUploadComplete(true);
    setSelectedFile(undefined);
  };

  return (
    <BoxContent id="uploadFile" containerClassName="" useFullWidth={true}>
      <div>
        <div>
          <div className="title-box-2">
            <h5 className="title-left">Upload File</h5>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-3">
              <div className="mb-2">Upload Folder Path</div>
              <input
                className="col-sm-12"
                type="text"
                value={uploadFolderPath}
                onChange={handleUploadFolderPathChange}
              ></input>
            </div>
            <div className="col-sm-12 mb-3">
              <FileUpload
                onFileUpload={handleFileUpload}
                onFileSelected={handleFileSelected}
                disabled={!selectedFile}
                imageHeight={undefined}
                imageWidth={undefined}
                imageUrl=""
              />
            </div>
            <div className="col-sm-12 mb-3">
              <p>{uploadFilePath}</p>
            </div>
            {isUploadComplete && (
              <div className="col-sm-12 mb-3">Upload Complete</div>
            )}
          </div>
        </div>
      </div>
    </BoxContent>
  );
}

export default UploadFile;
