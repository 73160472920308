import React, { useCallback, useContext, useEffect, useState } from "react";
import BoxContent from "../common/BoxContent";
import Moment from "react-moment";
import PostService from "../../services/postService";
import DeleteConfirmBox from "../common/DeleteConfirmBox";
import UserContext from "../../context/userContext";
import Post from "../../models/post";

import EditIcon from "../../assets/images/edit.png";
import DeleteIcon from "../../assets/images/delete.png";

// const posts = [
//   {
//     id: 1,
//     title: "Onion Architecture",
//     shortLink: "onion-architecture",
//     isPublic: false,
//     publishDate: "2020-08-03",
//   },
//   {
//     id: 2,
//     title: "Don't use DTOs",
//     shortLink: "dont-use-dtos",
//     isPublic: true,
//     publishDate: "2020-08-04",
//   },
//   {
//     id: 3,
//     title: "Repository and Unit of Work",
//     shortLink: "repository-and-unit-of-work",
//     isPublic: true,
//     publishDate: "2020-08-05",
//   },
// ];

const Posts: React.FC<{ history: any }> = ({ history }) => {
  const user = useContext(UserContext);
  const [posts, setPosts] = useState([]);
  const [deleteItem, setDeleteItem] = useState<Post>();
  const [postService] = useState<PostService>(new PostService());

  const loadPosts = useCallback(async () => {
    const { ok, data: posts } = await postService.getPosts();
    if (ok) {
      setPosts(posts as []);
    }
  }, [postService]);

  useEffect(() => {
    const init = async () => {
      await loadPosts();
    };
    init();
  }, [loadPosts]);

  const handleNew = () => {
    // alert("New Item");
    history.push("/admin/posts/new");
  };

  const handleEdit = (item: Post) => {
    // alert(`"Edit Item ${item.id}"`);
    history.push(`/admin/posts/${item.idPost}`);
  };

  const handleDeleteClick = (e: any, item: Post) => {
    e.stopPropagation();
    //alert(`"Delete Item ${item.idPost}"`);
    setDeleteItem(item);
  };

  const handleDelete = async () => {
    //alert(`"Delete Item ${deleteItem.idPost}"`);
    await postService.deletePost((deleteItem as any).idPost);
    await loadPosts();
  };

  return (
    <>
      <BoxContent id="posts" containerClassName="" useFullWidth={true}>
        <div className="row">
          <div className="col-sm-6">
            <h1 className="admin-page-title">Posts</h1>
          </div>
          <div className="col-sm-6 input-form">
            <div className="container-fluid">
              <div className="row justify-content-end button-new">
                <button
                  className="button button-a button-normal button-rouded"
                  style={{ width: "auto" }}
                  onClick={() => handleNew()}
                >
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="d-flex justify-content-end">
            <p>{posts.length} posts</p>
          </div>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Title</th>
              <th scope="col">Short Link</th>
              <th scope="col">Is Public</th>
              <th scope="col">Publish Date</th>
              <th className="table-edit-column" />
              <th className="table-edit-column" />
            </tr>
          </thead>
          <tbody>
            {posts.map((item: Post) => (
              <tr
                key={item.idPost.toString()}
                onClick={() => handleEdit(item)}
                className="table-edit-row"
              >
                <th scope="row">{item.idPost}</th>
                <td>{item.title}</td>
                <td>{item.shortLink}</td>
                <td>{item.isPublic.toString()}</td>
                <td>
                  <Moment format="MMM Do YYYY">{item.publishDate}</Moment>
                </td>
                <td>
                  <img className="img-edit" src={EditIcon} alt="" />
                </td>
                <td onClick={(e) => handleDeleteClick(e, item)}>
                  <img
                    className="img-edit"
                    src={DeleteIcon}
                    alt=""
                    onClick={(e) => handleDeleteClick(e, item)}
                    data-bs-toggle="modal"
                    data-bs-target="#confirm"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </BoxContent>
      <DeleteConfirmBox
        handleDelete={handleDelete}
        disableDeleteButton={user?.isAdminDemo}
      />
    </>
  );
};

export default Posts;
