import httpService from "./httpService";

const baseEndpoint = "/userProfiles";
const updateProfileImageEndpoint = "/updateProfileImage";

const upateProfileImage = (formData: any) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpService.post(baseEndpoint + updateProfileImageEndpoint, formData, {
    headers,
  });
};

const exportObject = { upateProfileImage };

export default exportObject;
