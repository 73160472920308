import React, { useState, useEffect } from "react";
import {
  Link,
  NavLink,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import User from "../models/user";
import { getFullUrl } from "../services/httpService";
// import ProfileImage from "../assets/images/profile.jpg";

interface RouteProps extends RouteComponentProps<any> {
  user: User | undefined;
}

const NavBar: React.FC<RouteProps> = ({ location, user }) => {
  const isHome =
    location.pathname.toLowerCase() === "/" ||
    location.pathname.toLowerCase() === "/home";

  useEffect(() => {
    closeHamburgerMenu();
  }, [location.pathname]);

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [hamburgerButtonClassName, setHamburgerButtonClassName] = useState(
    "navbar-toggler collapsed"
  );
  const [menuButtonsClassName, setMenuButtonsClassName] = useState(
    "navbar-collapse collapse justify-content-end"
  );

  const openHamburgerMenu = () => {
    setHamburgerButtonClassName("navbar-toggler");
    setMenuButtonsClassName(
      "navbar-collapse collapse justify-content-end show"
    );
    setIsHamburgerMenuOpen(true);
  };

  const closeHamburgerMenu = () => {
    setHamburgerButtonClassName("navbar-toggler collapsed");
    setMenuButtonsClassName("navbar-collapse collapse justify-content-end");
    setIsHamburgerMenuOpen(false);
  };

  const handleOnHamburgerClick = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
    if (!isHamburgerMenuOpen) {
      openHamburgerMenu();
    } else {
      closeHamburgerMenu();
    }
  };

  let navBarClassName = "navbar navbar-b navbar-expand-md fixed-top ";
  let navBarClassNameHome = isHome ? "navbar-trans" : "navbar-reduce";
  navBarClassNameHome += isHamburgerMenuOpen ? " navbar-black" : "";
  navBarClassName += navBarClassNameHome;

  // closed
  // let hamburgerButtonClassName = "navbar-toggler collapsed";
  // let menuButtonsClassName = "navbar-collapse justify-content-end collapse";

  // open
  // let hamburgerButtonClassName = "navbar-toggler";
  // let menuButtonsClassName = "navbar-collapse collapse justify-content-end show";

  return (
    <nav className={navBarClassName} id="mainNav">
      <div className="container">
        <div className="d-flex flex-nowrap">
          <Link className="navbar-brand js-scroll" to="/">
            DOBRO
          </Link>
          <div className="navbar-brand js-scroll socials-container">
            <a
              href="https://www.linkedin.com/in/ddobriceanu"
              target="_blank"
              rel="noopener noreferrer"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="LinkedIn"
            >
              <i className="fa fa-linkedin social-i linkedin"></i>
            </a>
            <a
              href="https://www.twitter.com/dandobriceanu"
              target="_blank"
              rel="noopener noreferrer"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Twitter"
            >
              <i className="fa fa-twitter social-i twitter"></i>
            </a>
          </div>
        </div>
        <button
          className={hamburgerButtonClassName}
          type="button"
          data-toggle="collapse"
          data-target="#navbarDefault"
          aria-controls="navbarDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleOnHamburgerClick}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className={menuButtonsClassName} id="navbarDefault">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link js-scroll" exact to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link js-scroll" to="/about">
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link js-scroll" to="/services">
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link js-scroll" to="/work">
                Work
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link js-scroll" to="/certifications">
                Certifications
              </NavLink>
            </li>
            {/*<li className="nav-item">*/}
            {/*  <NavLink className="nav-link js-scroll" to="/contact">*/}
            {/*    Contact*/}
            {/*  </NavLink>*/}
            {/*</li>*/}
            <li className="nav-item" style={{ display: "" }}>
              <NavLink className="nav-link js-scroll" to="/blog">
                Blog
              </NavLink>
            </li>
            <li className="nav-item" style={{ display: "" }}>
              <NavLink className="nav-link js-scroll" to="/chat">
                Chat
              </NavLink>
            </li>
            {!user && (
              <li className="nav-item">
                <NavLink className="nav-link js-scroll" to="/login">
                  Login
                </NavLink>
              </li>
            )}
            {user && (
              <>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link"
                    href="#user"
                    data-bs-toggle="dropdown"
                  >
                    {user.userProfile?.profileImageUrl && (
                      <>
                        <img
                          src={getFullUrl(user.userProfile?.profileImageUrl)}
                          alt=""
                          className="nav-avatar rounded-circle"
                        />
                        <div className="nav-avatar-placeholder"></div>
                      </>
                    )}
                    <span>Me</span>
                  </a>
                  <div className="dropdown-menu">
                    <NavLink className="dropdown-item" to="/profile">
                      {user.name ? user.name : user.username} Profile
                    </NavLink>
                    {(user.isAdmin || user.isAdminDemo) && (
                      <NavLink className="dropdown-item" to="/admin">
                        Administration
                      </NavLink>
                    )}
                    <NavLink className="dropdown-item" to="/logout">
                      Logout
                    </NavLink>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default withRouter(NavBar);
