import { NavLink } from "react-router-dom";

function SideBar(props: any) {
  return (
    <div className="border-right sidebar-wrapper">
      <div className="list-group list-group-flush">
        <NavLink
          className="sidebar-heading list-group-item list-group-item-action"
          to="/admin"
          exact
        >
          Admin
        </NavLink>
        <NavLink
          className="list-group-item list-group-item-action"
          to="/admin/posts"
        >
          Posts
        </NavLink>
        {/* <NavLink
          className="list-group-item list-group-item-action"
          to="/admin/tags"
        >
          Tags
        </NavLink> */}
        <NavLink
          className="list-group-item list-group-item-action"
          to="/admin/upload-files"
        >
          Upload Files
        </NavLink>
        <NavLink
          className="list-group-item list-group-item-action"
          to="/admin/users"
        >
          Users
        </NavLink>
      </div>
    </div>
  );
}

export default SideBar;
