import User from "../models/user";
import UserData from "../models/userData";
import httpService, { setToken } from "./httpService";

const baseEndpoint = "/identity";
//const loginEndpoint = "/login";
const registerEndpoint = "/register";
const registerConfirmEndpoint = "/registerConfirmation";
const resendAccountConfirmationEndpoint = "/resendAccountConfirmation";
const forgotPasswordEndpoint = "/forgotPassword";
const resetPasswordEndpoint = "/resetPassword";
const getCurrentUserEndpoint = "/getCurrentUser";
const updateCurrentUserEndpoint = "/updateCurrentUser";

class LoginResponse {
  isSuccess: boolean = false;
  errorMessage: string = "";
  token: string = "";
  user: User | undefined;
}

const getUserData = (): UserData | undefined => {
  var userDataString = localStorage.getItem("userData");
  if (userDataString) {
    const userData: UserData = JSON.parse(userDataString);
    return userData;
  }
  return undefined;
};

const getToken = (): string | undefined => {
  return getUserData()?.token;
};

setToken(getToken());

// todo - fix login - jwt was removed, user is now received
const login = async (username: string, password: string) => {
  const result = await httpService.post<LoginResponse>(
    `${baseEndpoint}/login`,
    {
      username,
      password,
    }
  );
  if (result.ok) {
    const { data: response } = result;
    if (response) {
      const userData = new UserData();
      userData.token = response.token;
      userData.user = response.user;
      localStorage.setItem("userData", JSON.stringify(userData));
    }
  }
  return result;
};

const logout = () => {
  localStorage.removeItem("userData");
};

const getCurrentUser = (): User | undefined => {
  return getUserData()?.user;
};

const register = (username: string, password: string, email: string) => {
  return httpService.post(baseEndpoint + registerEndpoint, {
    username,
    password,
    email,
  });
};

const registerConfirmation = (accountConfirmationToken: string) => {
  return httpService.post(baseEndpoint + registerConfirmEndpoint, {
    accountConfirmationToken,
  });
};

const resendAccountConfirmation = (username: string) => {
  return httpService.post(baseEndpoint + resendAccountConfirmationEndpoint, {
    username,
  });
};

const forgotPassword = (username: string) => {
  return httpService.post(baseEndpoint + forgotPasswordEndpoint, { username });
};

const resetPassword = (resetPasswordToken: string, newPassword: string) => {
  return httpService.post(baseEndpoint + resetPasswordEndpoint, {
    resetPasswordToken,
    newPassword,
  });
};

const getCurrentUserFromServer = () => {
  return httpService.get<User | undefined>(
    baseEndpoint + getCurrentUserEndpoint
  );
};

const updateCurrentUser = (
  firstName: string,
  lastName: string,
  about: string
) => {
  return httpService.post(baseEndpoint + updateCurrentUserEndpoint, {
    firstName,
    lastName,
    about,
  });
};

const exportObject = {
  login,
  logout,
  getCurrentUser,
  register,
  registerConfirmation,
  resendAccountConfirmation,
  forgotPassword,
  resetPassword,
  getCurrentUserFromServer,
  updateCurrentUser,
  getToken,
};

export default exportObject;
