import React, { useEffect, useState } from "react";
import Content from "./common/Content";
import PostCard from "./common/PostCard";
// import ProjectMySiteImage from "../assets/images/project-my-site.jpg";
import PostService from "../services/postService";
import { getFullUrl } from "../services/httpService";
import Post from "../models/post";

const Blog: React.FC<{ setIsBusy: any }> = ({ setIsBusy }) => {
  const [leftPosts, setLeftPosts] = useState<Post[]>([]);
  const [rightPosts, setRightPosts] = useState<Post[]>([]);

  const [postService] = useState<PostService>(new PostService());

  useEffect(() => {
    const loadPosts = async () => {
      //console.log("Blog.loadPosts()");
      const { ok, data: postList } = await postService.getPublicPosts();
      const posts = postList as any[];
      if (ok) {
        //posts = posts.slice(0, 4);
        posts.forEach((post) => {
          post.imageUrl = getFullUrl(post.imageUrl);
        });
        const leftPostsCount = Math.ceil(posts.length / 5);
        const leftPosts = posts.slice(0, leftPostsCount);
        const rightPosts = posts.slice(leftPostsCount, posts.length);
        setLeftPosts(leftPosts);
        setRightPosts(rightPosts);
      }
    };

    const init = async () => {
      setIsBusy(true);
      await loadPosts();
      setIsBusy(false);
    };
    init();
  }, [setIsBusy, postService]);

  // let posts = [
  //   {
  //     id: 1,
  //     imageUrl: ProjectMySiteImage,
  //     tag: "News",
  //     title: "Onion Architecture",
  //     shortLink: "onion-architecture",
  //   },
  //   {
  //     id: 2,
  //     imageUrl: ProjectMySiteImage,
  //     categoryName: "News",
  //     title: "Post 2",
  //   },
  //   {
  //     id: 3,
  //     imageUrl: ProjectMySiteImage,
  //     categoryName: "News",
  //     title: "Post 3",
  //   },
  //   {
  //     id: 4,
  //     imageUrl: ProjectMySiteImage,
  //     categoryName: "News",
  //     title: "Post 4",
  //   },
  //   {
  //     id: 5,
  //     imageUrl: ProjectMySiteImage,
  //     categoryName: "News",
  //     title: "Post 5",
  //   },
  //   {
  //     id: 6,
  //     imageUrl: ProjectMySiteImage,
  //     categoryName: "News",
  //     title: "Post 6",
  //   },
  // ];

  return (
    <Content>
      <div className="blog-content">
        <h1 className="blog-title">BLOG</h1>
        <div className="row">
          <div className="col-sm-6">
            {leftPosts.map((post) => (
              <PostCard
                imageClassName="blog-card-img"
                post={post}
                key={`blog__${post.idPost}`}
              />
            ))}
          </div>
          <div className="col-sm-6">
            <div className="row">
              {rightPosts.map((post) => (
                <div className="col-sm-6" key={`blog__${post.idPost}`}>
                  <PostCard imageClassName="blog-card-img-right" post={post} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Blog;
