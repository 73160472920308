import React, { useContext, useEffect, useState } from "react";
import UserContext from "../context/userContext";
import { getFullUrl } from "../services/httpService";
import userProfileService from "../services/userProfileService";
import identityService from "../services/identityService";
import BoxContent from "./common/BoxContent";
import FileUpload from "./common/FileUpload";

function About(props: any) {
  const user = useContext(UserContext);
  //console.log("userContext", userContext);
  const [imageUrl, setImageUrl] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [about, setAbout] = useState("");

  useEffect(() => {
    //console.log("userContex", userContext);
    if (user && user.userProfile) {
      setImageUrl(user.userProfile.profileImageUrl);
      const init = async () => {
        const { ok, data: usr } =
          await identityService.getCurrentUserFromServer();
        const user = usr as any;
        if (ok) {
          setFirstName(user.firstName);
          setLastName(user.lastName);
          setAbout(user.userProfile.about ? user.userProfile.about : "");
        } else {
          alert("Error getting current user from server.");
        }
      };
      init();
    }
  }, [user]);

  const handleImageUpload = async (selectedImage: any) => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    //console.log(selectedImage);
    var result = await userProfileService.upateProfileImage(formData);
    if (!result.ok) {
      alert("upateProfileImage error");
    }
  };

  const handleFirstNameChange = (event: any) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: any) => {
    setLastName(event.target.value);
  };

  const handleAboutChange = (event: any) => {
    setAbout(event.target.value);
  };

  const handleUpdate = async () => {
    await identityService.updateCurrentUser(firstName, lastName, about);
  };

  return (
    <BoxContent
      id="about"
      containerClassName="sect-pt6 route"
      useFullWidth={false}
    >
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-sm-6 col-md-5">
              <div className="about-img">
                <FileUpload
                  isImage={true}
                  imageUrl={getFullUrl(imageUrl)}
                  onFileUpload={handleImageUpload}
                  disabled={!user || (user && user.isAdminDemo)}
                  imageHeight={undefined}
                  imageWidth={undefined}
                  onFileSelected={() => {}}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-7">
              <div className="about-info">
                <p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={firstName}
                    onChange={handleFirstNameChange}
                  />
                </p>
                <p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={handleLastNameChange}
                  />
                </p>
                <p>
                  <span className="title-s">Username: </span>{" "}
                  <span>{user?.username}</span>
                </p>
                <p>
                  <span className="title-s">Email: </span>{" "}
                  <span>{user?.email}</span>
                </p>
              </div>
              <div className="input-form" style={{ marginTop: "2.73rem" }}>
                <button
                  type="submit"
                  className="button button-a button-normal button-rouded"
                  onClick={handleUpdate}
                  disabled={!user || (user && user.isAdminDemo)}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="about-me pt-4 pt-md-0">
            <div className="title-box-2">
              <h5 className="title-left-underline">About me</h5>
            </div>
            <p className="lead">
              <textarea
                className="form-control"
                rows={3}
                value={about}
                onChange={handleAboutChange}
              />
            </p>
          </div>
        </div>
      </div>
    </BoxContent>
  );
}

export default About;
