import React, { useContext, useState } from "react";
import Joi from "joi";
import BoxContent from "./common/BoxContent";
import Form from "./common/Form";
import FormInput from "./common/FormInput";
import FormButton from "./common/FormButton";
import { Link, Redirect } from "react-router-dom";
import identityService from "../services/identityService";
import httpErrorMessage from "../services/httpServiceErrorMessage";
import UserContext from "../context/userContext";

const Login: React.FC<{ history: any; location: any }> = ({
  history,
  location,
}) => {
  const user = useContext(UserContext);

  const [data, setData] = useState({ username: "", password: "" });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loginError, setLoginError] = useState("");
  const [canResendAccountConfirmation, setCanResendAccountConfirmation] =
    useState(false);

  const schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };

  const doSubmit = () => {
    return new Promise(async (resolve) => {
      setLoginError("");
      const result = await identityService.login(data.username, data.password);
      if (!result.ok) {
        setLoginError(httpErrorMessage.getErrorMessage(result));
        setCanResendAccountConfirmation(
          (result.data as any)?.canResendAccountConfirmation
        );
        // TODO: if entering a bad pw and going to home, chrome thinks you entered a password? because if you go to login and don't enter anything and then go to home it doesn't say anything
        //setData({ username: "", password: "" }); // this doesn't look to fix the issue above
        resolve(false);
        return;
      }
      setLoginError("");
      setData({ username: "", password: "" });
      resolve(true);
    });
  };

  const onSubmitOk = () => {
    const { state } = location;
    window.location = state ? state.from.pathname : "/";

    // history.push("/");
    //window.location = "/"; // fully reload the app so the useEffect can be called again, as it is called only once at app init
  };

  const handleResendAccountConfirmation = async () => {
    await identityService.resendAccountConfirmation(data.username);
    setLoginError("Please check your email and confirm the account.");
  };

  return (
    <>
      {user && <Redirect to="/" />}
      <BoxContent
        id="login"
        containerClassName="work-mf sect-pt6 route"
        useFullWidth={false}
      >
        <div>
          <div className="col-sm-12">
            <div className="title-box-2">
              <h5 className="title-left">Login</h5>
            </div>
            <div>
              <Form
                data={data}
                setData={setData}
                schema={schema}
                errors={errors}
                setErrors={setErrors}
                doSubmit={doSubmit}
                onSubmitOk={onSubmitOk}
                submitErrorMessage={loginError}
                submitOkMessage=""
              >
                <FormInput
                  name="username"
                  placeholder="Username"
                  error={errors["username"]}
                  inputType="FormInput"
                />
                <FormInput
                  name="password"
                  placeholder="Password"
                  error={errors["password"]}
                  inputType="FormInput"
                  isPassword
                />
                <FormButton
                  label="Login"
                  inputType="FormButton"
                  disabled={false}
                />
              </Form>
              {canResendAccountConfirmation && (
                <div>
                  <p>
                    Resend Account Confirmation Email?{" "}
                    <Link
                      to="#"
                      onClick={() => handleResendAccountConfirmation()}
                    >
                      Resend Confirmation
                    </Link>
                  </p>
                </div>
              )}
              <div>
                <p>
                  Forgot Password?{" "}
                  <Link to="/forgot-password">Reset Password</Link>
                </p>
              </div>
              <div>
                <p>
                  No Account? <Link to="/register">Register</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </BoxContent>
    </>
  );
};

export default Login;
