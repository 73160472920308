import React from "react";
import BoxContent from "../common/BoxContent";
import EditIcon from "../../assets/images/edit.png";
import DeleteIcon from "../../assets/images/delete.png";

const tags = [
  {
    id: 1,
    tagName: "Architecture",
  },
  {
    id: 2,
    tagName: "Software Design",
  },
  {
    id: 3,
    tagName: ".NET",
  },
  {
    id: 4,
    tagName: "React",
  },
  {
    id: 5,
    tagName: "Mobile",
  },
  {
    id: 6,
    tagName: "Web",
  },
  {
    id: 7,
    tagName: "WPF",
  },
];

const Tags: React.FC<{ history: any }> = ({ history }) => {
  const handleNew = () => {
    alert("New Item");
  };
  const handleEdit = (item: any) => {
    // alert(`"Edit Item ${item.id}"`);
    history.push(`/admin/tags/${item.id}`);
  };
  const handleDelete = (e: any, item: any) => {
    e.stopPropagation();
    alert(`"Delete Item ${item.id}"`);
  };
  return (
    <BoxContent id="Tags" containerClassName="" useFullWidth>
      <div className="row">
        <div className="col-sm-6">
          <h1 className="admin-page-title">Tags</h1>
        </div>
        <div className="col-sm-6">
          <div className="container-fluid">
            <div className="row justify-content-end button-new">
              <button
                className="button button-a button-normal button-rouded"
                onClick={() => handleNew()}
              >
                Add New
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-end">
          <p>{tags.length} tags</p>
        </div>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Tag Name</th>
            <th className="table-edit-column" />
            <th className="table-edit-column" />
          </tr>
        </thead>
        <tbody>
          {tags.map((item) => (
            <tr
              key={item.id.toString()}
              onClick={() => handleEdit(item)}
              className="table-edit-row"
            >
              <th scope="row">{item.id}</th>
              <td>{item.tagName}</td>
              <td>
                <img className="img-edit" src={EditIcon} alt="" />
              </td>
              <td onClick={(e) => handleDelete(e, item)}>
                <img
                  className="img-edit"
                  src={DeleteIcon}
                  alt=""
                  onClick={(e) => handleDelete(e, item)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </BoxContent>
  );
};

export default Tags;
