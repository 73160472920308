import React, { useContext, useEffect, useState } from "react";
import BoxContent from "../common/BoxContent";
import userService from "../../services/userService";
import DeleteConfirmBox from "../common/DeleteConfirmBox";
import UserContext from "../../context/userContext";
import User from "../../models/user";

import EditIcon from "../../assets/images/edit.png";
import DeleteIcon from "../../assets/images/delete.png";

// const data = [
//   {
//     nr: 1,
//     firstName: "Mark",
//     lastName: "Otto",
//     handle: "@mdo",
//   },
//   {
//     nr: 2,
//     firstName: "Jacob",
//     lastName: "Thornton",
//     handle: "@fat",
//   },
//   {
//     nr: 3,
//     firstName: "Larry",
//     handle: "@twitter",
//   },
// ];

const Users: React.FC<{ history: any }> = ({ history }) => {
  const user = useContext(UserContext);
  const [users, setUsers] = useState<User[]>([]);
  const [deleteItem, setDeleteItem] = useState<User>();

  useEffect(() => {
    const init = async () => {
      await loadUsers();
    };
    init();
  }, []);

  const loadUsers = async () => {
    const response = await userService.getUsers();
    if (response.ok) {
      setUsers(response.data as []);
    }
  };

  const handleNew = () => {
    history.push("/admin/users/new");
  };

  const handleEdit = (user: User) => {
    //alert(`"Edit Item ${user.idUser}"`);
    history.push(`/admin/users/${user.idUser}`);
  };

  const handleDeleteClick = (e: any, item: User) => {
    e.stopPropagation();
    //alert(`"Delete Item ${item.idUser}"`);
    setDeleteItem(item);
  };

  const handleDelete = async () => {
    await userService.deleteUser((deleteItem as any).idUser);
    await loadUsers();
  };

  return (
    <>
      <BoxContent id="users" containerClassName="" useFullWidth={true}>
        <div className="row">
          <div className="col-sm-6">
            <h1 className="admin-page-title">Users</h1>
          </div>
          <div className="col-sm-6 input-form">
            <div className="container-fluid">
              <div className="row justify-content-end button-new">
                <button
                  className="button button-a button-normal button-rouded"
                  style={{ width: "auto" }}
                  onClick={() => handleNew()}
                >
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="d-flex justify-content-end">
            <p>{users.length} users</p>
          </div>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">First</th>
              <th scope="col">Last</th>
              <th scope="col">Username</th>
              <th scope="col">Email</th>
              <th scope="col">Is Admin</th>
              <th scope="col">Is Blocked</th>
              <th className="table-edit-column" />
              <th className="table-edit-column" />
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr
                key={user.idUser.toString()}
                onClick={() => handleEdit(user)}
                className="table-edit-row"
              >
                <th scope="row">{user.idUser}</th>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{user.isAdmin.toString()}</td>
                <td>{user.isBlocked.toString()}</td>
                <td>
                  <img className="img-edit" src={EditIcon} alt="" />
                </td>
                <td onClick={(e) => handleDeleteClick(e, user)}>
                  <img
                    className="img-edit"
                    src={DeleteIcon}
                    alt=""
                    onClick={(e) => handleDeleteClick(e, user)}
                    data-bs-toggle="modal"
                    data-bs-target="#confirm"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </BoxContent>
      <DeleteConfirmBox
        handleDelete={handleDelete}
        disableDeleteButton={user?.isAdminDemo}
      />
    </>
  );
};

export default Users;
