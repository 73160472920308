import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";
import statisticsService from "../../../services/statisticsService";
import moment from "moment";

function ChartMessages(props: any) {
  const chartRef = useRef<HTMLCanvasElement>(null);

  // const transparentize = (color: any, opacity: any) => {
  //   var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  //   return (global as any).Color(color).alpha(alpha).rgbString();
  // };

  useEffect(() => {
    let chart: Chart;

    const init = async () => {
      const chartColors = {
        red: "rgb(255, 99, 132)",
        orange: "rgb(255, 159, 64)",
        yellow: "rgb(255, 205, 86)",
        green: "rgb(75, 192, 192)",
        blue: "rgb(54, 162, 235)",
        purple: "rgb(153, 102, 255)",
        grey: "rgb(201, 203, 207)",
      };

      const myChartRef = (chartRef.current as any)?.getContext("2d");

      //   const labels = ["Day1", "Day2", "Day3", "Day4", "Day5", "Day6"];
      //   const data = [10, 20, 30, 40, 10, 20];

      let chartLabel = "Chat Messages";
      let labels = [];
      let data = [];

      var { ok, data: _chartData } =
        await statisticsService.getMainChatMessagesPerDay();
      if (ok) {
        const chartData = _chartData as any;
        let formattedDate: any[] = [];
        chartData.labels.forEach((date: any) => {
          formattedDate.push(moment(date).format("MMM Do YYYY"));
        });
        labels = formattedDate;
        data = chartData.data;
      }

      Chart.register(...registerables);

      // area chart
      chart = new Chart(myChartRef, {
        type: "line",
        data: {
          //Bring in data
          labels: labels,
          datasets: [
            {
              //backgroundColor: transparentize(chartColors.red, 1),
              backgroundColor: "rgb(255, 99, 132, 0.5)",
              borderColor: chartColors.red,
              data: data,
              label: chartLabel,
              fill: true,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          spanGaps: false,
          elements: {
            line: {
              tension: 0.000001,
            },
          },
          plugins: {
            filler: {
              propagate: false,
            },
          },
          scales: {
            x: {
              ticks: {
                autoSkip: false,
                maxRotation: 0,
              },
            },
          },
        },
      });
    };
    init();
    return () => {
      //console.log("line-chart-destory");
      chart.destroy();
    };
  }, []);

  return (
    <div>
      <canvas ref={chartRef} />
    </div>
  );
}

export default ChartMessages;
