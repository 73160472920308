import React, { useState } from "react";
import Joi from "joi";
import BoxContent from "./common/BoxContent";
import Form from "./common/Form";
import FormInput from "./common/FormInput";
import FormButton from "./common/FormButton";
import identityService from "../services/identityService";
import httpErrorMessage from "../services/httpServiceErrorMessage";

const Register = () => {
  const [data, setData] = useState({
    email: "",
    username: "",
    password: "",
    reenterPassword: "",
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [registerError, setRegisterError] = useState("");

  const schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
    reenterPassword: Joi.string().required().label("Reenter Password"),
    email: Joi.string()
      .required()
      .email({ tlds: { allow: false } })
      .label("Email"),
  };

  const doSubmit = () => {
    return new Promise(async (resolve) => {
      setRegisterError("");
      const result = await identityService.register(
        data.username,
        data.password,
        data.email
      );
      if (!result.ok) {
        setRegisterError(httpErrorMessage.getErrorMessage(result));
        resolve(false);
        return;
      }
      setRegisterError("");
      setData({ username: "", password: "", reenterPassword: "", email: "" });
      resolve(true);
    });
  };

  return (
    <BoxContent
      id="register"
      containerClassName="work-mf sect-pt6 route"
      useFullWidth={false}
    >
      <div>
        <div className="col-sm-12">
          <div className="title-box-2">
            <h5 className="title-left">Register</h5>
          </div>
          <div>
            <Form
              data={data}
              setData={setData}
              schema={schema}
              errors={errors}
              setErrors={setErrors}
              doSubmit={doSubmit}
              onSubmitOk={() => {}}
              submitErrorMessage={registerError}
              submitOkMessage="Your account was created. Please check your email and confirm the account."
            >
              <FormInput
                name="username"
                placeholder="Username"
                error={errors["username"]}
                inputType="FormInput"
              />
              <FormInput
                name="password"
                placeholder="Password"
                error={errors["password"]}
                inputType="FormInput"
                isPassword
              />
              <FormInput
                name="reenterPassword"
                placeholder="Reenter Password"
                error={errors["reenterPassword"]}
                inputType="FormInput"
                isPassword
              />
              <FormInput
                name="email"
                placeholder="Email"
                error={errors["email"]}
                inputType="FormInput"
              />
              <FormButton
                label="Register"
                inputType="FormButton"
                disabled={false}
              />
            </Form>
          </div>
        </div>
      </div>
    </BoxContent>
  );
};

export default Register;
