import React from "react";
import BoxContent from "./common/BoxContent";

function NotFound(props: any) {
  return (
    <BoxContent
      id="notFound"
      containerClassName="work-mf sect-pt6 route"
      useFullWidth={false}
    >
      <h4>Not Found</h4>
    </BoxContent>
  );
}

export default NotFound;
