import React from "react";

const ServiceCard: React.FC<{
  title: string;
  description: string;
  iconClassName: string;
}> = ({ title, description, iconClassName }) => {
  return (
    <div className="col-md-4">
      <div className="service-box">
        <div className="service-ico">
          <span className="ico-circle">
            <i className={iconClassName}></i>
          </span>
        </div>
        <div className="service-content">
          <h2 className="s-title">{title}</h2>
          <p className="s-description text-center">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
