import React, { useState, useEffect, useContext } from "react";
import BoxContent from "../common/BoxContent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import PostService from "../../services/postService";
import PostView from "../PostView";
import FileUpload from "../common/FileUpload";
import Post from "../../models/post";
import User from "../../models/user";

import { getFullUrl } from "../../services/httpService";
import UserContext from "../../context/userContext";
import { KEY_S } from "keycode-js";
import { Link } from "react-router-dom";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const PostEdit: React.FC<{ match: any; history: any }> = ({
  match,
  history,
}) => {
  const user = useContext<User | undefined>(UserContext);
  const idPost = match.params.id;
  const [isNew, setIsNew] = useState(false);
  const [title, setTitle] = useState("");
  const [shortLink, setShortLink] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [publishDate, setPublishDate] = useState<Date>(new Date());
  const [imageUrl, setImageUrl] = useState("");
  const [content, setContent] = useState("");
  const [postService] = useState<PostService>(new PostService());

  const editorModules = {
    syntax: true,
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ["clean"], // remove formatting button

      ["link", "image", "video"], // link and image, video
    ],
  };

  useEffect(() => {
    const init = async () => {
      if (idPost === "new") {
        setContent("*This is italic text*");
        setIsNew(true);
      } else {
        const { ok, data: post } = (await postService.getPostById(idPost)) as {
          ok: any;
          data: Post;
        };
        if (ok) {
          setPostForUi(post);
        } else {
          history.push("/not-found");
        }
      }
    };
    init();
  }, [idPost, history, postService]);

  const handleTitleChange = (event: any) => {
    setTitle(event.target.value);
  };

  const handleShortLinkChange = (event: any) => {
    setShortLink(event.target.value);
  };

  const handleIsPublicChange = (event: any) => {
    setIsPublic(event.target.checked);
  };

  const handleSave = async () => {
    if (isNew) {
      var postFromUi = getPostFromUi();
      const { ok, data: post } = (await postService.createPost(postFromUi)) as {
        ok: any;
        data: Post;
      };
      if (ok) {
        const idPost = (post as any).idPost;
        //history.push(`/admin/posts/${idPost}`); // this causes isNew not to get updated the first time and hitting save will create new post over and over
        window.location.href = `/admin/posts/${idPost}`;
      }
      return;
    }
    await postService.updatePost(getPostFromUi());
  };

  const getPostFromUi: any = () => {
    return {
      idPost: isNew ? 0 : idPost,
      title,
      isPublic,
      content,
      imageUrl,
      publishDate: moment(publishDate),
      shortLink,
    };
  };

  const setPostForUi = (post: Post) => {
    setTitle(post.title);
    setContent(post.content);
    setIsPublic(post.isPublic);
    setImageUrl(post.imageUrl);
    setPublishDate(moment(post.publishDate).toDate());
    setShortLink(post.shortLink);
  };

  const handleImageUpload = async (selectedImage: any) => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    formData.append("idPost", idPost);
    //console.log(formData);
    var { ok, data: uploadFile } = await postService.updatePostImage(formData);
    if (!ok) {
      alert("updatePostImage error");
    } else {
      setImageUrl((uploadFile as any).url);
    }
  };

  // const handleEditorChange = (event: any) => {
  //   setContent(event.text);
  // };

  const handleEditorKeyDown = (e: any) => {
    if (e.ctrlKey && e.which === KEY_S) {
      e.preventDefault();
      handleSave();
    }
  };

  return (
    <>
      <BoxContent id="postEdit" containerClassName="" useFullWidth={true}>
        <div className="row">
          <div className="col-sm-6">
            <h1 className="admin-page-title">Edit Post {idPost}</h1>
          </div>
          <div className="col-sm-6 input-form">
            <div className="container-fluid">
              <div className="row justify-content-end button-new">
                <button
                  style={{
                    position: "fixed",
                    top: "125px",
                    zIndex: "100",
                    width: "auto",
                  }}
                  className="button button-a button-normal button-rouded"
                  onClick={() => handleSave()}
                  disabled={user?.isAdminDemo}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mb-3">
            <div className="mb-2">Title</div>
            <input
              className="col-sm-12"
              type="text"
              value={title}
              onChange={handleTitleChange}
            ></input>
          </div>
          <div className="col-sm-12 mb-3">
            <div className="mb-2">Short Link</div>
            <input
              className="col-sm-12"
              type="text"
              value={shortLink}
              onChange={handleShortLinkChange}
            ></input>
          </div>
          <div className="col-sm-12 mb-3">
            <input
              type="checkbox"
              id="isPublicCheckbox"
              checked={isPublic}
              onChange={handleIsPublicChange}
            />
            <label
              className="form-check-label checkbox-label"
              htmlFor="isPublicCheckbox"
            >
              Is Public
            </label>
          </div>
          <div className="col-sm-12 mb-3">
            <div className="mb-2">Publish Date</div>
            <div style={{ display: "inline-block" }}>
              <DatePicker
                selected={publishDate}
                onChange={(date: Date) => setPublishDate(date)}
                dateFormat="MMM do yyyy"
              />
            </div>
          </div>
          <div className="col-sm-12 mb-3">
            <div className="mb-2">Image</div>
            <FileUpload
              isImage={true}
              imageUrl={getFullUrl(imageUrl)}
              imageWidth={400}
              onFileUpload={handleImageUpload}
              onFileSelected={() => {}}
              disabled={user?.isAdminDemo}
              imageHeight={undefined}
            />
          </div>
          <div className="row">
            <div className="col-sm-6 mb-3">
              <div className="mb-2">Content Editor</div>
              <div onKeyDown={handleEditorKeyDown}>
                <ReactQuill
                  theme="snow"
                  modules={editorModules}
                  value={content}
                  onChange={setContent}
                />
              </div>
            </div>
            <div className="col-sm-6">
              Content Preview{" "}
              <Link to={`/blog/${shortLink}?preview`} target="_blank">
                Live View
              </Link>
              <div style={{ marginTop: 0 }}>
                <PostView
                  title={title}
                  imageUrl={getFullUrl(imageUrl)}
                  publishDate={publishDate}
                  content={content}
                  showTitle={false}
                />
              </div>
            </div>
          </div>
        </div>
      </BoxContent>
      <div style={{ marginBottom: "-60px" }}>
        <h1>Live View</h1>
        <Link to={`/blog/${shortLink}?preview`} target="_blank">
          Open in new window
        </Link>
      </div>
      <PostView
        title={title}
        imageUrl={getFullUrl(imageUrl)}
        publishDate={publishDate}
        content={content}
      />
    </>
  );
};

export default PostEdit;
