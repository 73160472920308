import React from "react";
import BoxContent from "./common/BoxContent";

function Unauthorized(props: any) {
  return (
    <BoxContent
      id="Unauthorized"
      containerClassName="work-mf sect-pt6 route"
      useFullWidth={false}
    >
      <h4>Unauthorized</h4>
    </BoxContent>
  );
}

export default Unauthorized;
