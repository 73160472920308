import UserProfile from "./userProfile";
import Role from "./role";

export default class User {
  idUser: number = 0;
  email: string = "";
  firstName: string = "";
  lastName: string = "";
  name: string = "";
  username: string = "";
  password: string = "";
  isAdmin: boolean = false;
  isAdminDemo: boolean = false;
  isConfirmed: boolean = false;
  isBlocked: boolean = false;
  userProfile: UserProfile | undefined;
  role: Role | undefined;
}
