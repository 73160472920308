import React from "react";

const Content: React.FC<{ children: any }> = ({ children }) => {
  return (
    <section className="sect-pt5">
      {/* container or container-fluid */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-12-no-margin">{children}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
