import React from "react";
import Moment from "react-moment";
import Content from "./common/Content";

const PostView: React.FC<{
  title: string;
  imageUrl: string;
  publishDate: Date;
  content: string;
  showTitle?: boolean;
}> = ({ title, imageUrl, publishDate, content, showTitle = true }) => {
  return (
    <Content>
      <div className="post">
        {showTitle && (
          <div
            className="post-image"
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          >
            <div className="post-image-bg">
              <h1 className="post-title">{title}</h1>
            </div>
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="col-sm-12 mb-3">
              <Moment format="MMM Do YYYY">{publishDate}</Moment>
            </div>
            <div className="col-sm-12 post-content">
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default PostView;
