import React, { useEffect, useState } from "react";
import BoxContent from "./common/BoxContent";
import identityService from "../services/identityService";

const RegisterConfirmation: React.FC<{ match: any }> = ({ match }) => {
  const accountConfirmationToken = match.params.accountConfirmationToken;
  const [isBusy, setIsBusy] = useState(true);
  const [result, setResult] = useState("");
  useEffect(() => {
    const init = async () => {
      setIsBusy(true);
      const result = await identityService.registerConfirmation(
        accountConfirmationToken
      );
      setIsBusy(false);
      if (!result.ok) {
        setResult("Error: " + (result.data as any).message);
        return;
      }
      setResult("Done, now you can proceed to login.");
    };

    init();
  }, [accountConfirmationToken]);

  return (
    <BoxContent
      id="registerConfirmation"
      containerClassName="work-mf sect-pt6 route"
      useFullWidth={false}
    >
      {isBusy && <div>Please wait...</div>}
      <div>{result}</div>
    </BoxContent>
  );
};

export default RegisterConfirmation;
