import React, { useCallback, useContext, useEffect, useState } from "react";
import UserContext from "../context/userContext";
import PostService from "../services/postService";
import BoxContent from "./common/BoxContent";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { getFullUrl } from "../services/httpService";
import PostComment from "../models/postComment";

const PostComments: React.FC<{ location: any; idPost: number }> = ({
  location,
  idPost,
}) => {
  const user = useContext(UserContext);

  const [comment, setComment] = useState("");
  const [comments, setComments] = useState<PostComment[]>([]);
  const [postService] = useState<PostService>(new PostService());

  const loadPostComments = useCallback(
    async (idPost: number) => {
      //console.log("PostComments.loadPostComments()");
      var { ok, data: comments } = await postService.getPublicPostComments(
        idPost
      );
      if (ok) {
        setComments(comments as []);
      }
    },
    [postService]
  );

  useEffect(() => {
    const init = async () => {
      if (idPost) {
        await loadPostComments(idPost);
      }
    };
    init();
  }, [idPost, loadPostComments]);

  const handleCommentChange = (e: any) => {
    setComment(e.target.value);
  };

  const handleAddComment = async (e: any) => {
    e.preventDefault(); // because we're in a form and this is a submit button
    var { ok } = await postService.createPublicPostComment(idPost, comment);
    if (ok) {
      setComment("");
      await loadPostComments(idPost);
    }
  };

  const handleDeleteComment = async (
    idPostComment: number,
    comment: string
  ) => {
    if (
      window.confirm(
        `Are you sure you want to delete the comment?\r\n"${comment}"`
      )
    ) {
      var { ok, data } = await postService.deletePostComment(idPostComment);
      if (ok) {
        setComment("");
        await loadPostComments(idPost);
      } else {
        alert((data as any).message);
      }
    }
  };

  return (
    <BoxContent
      id="postComments"
      containerClassName=""
      useFullWidth={false}
      isSmall={true}
    >
      <div className="box-comments">
        <div className="title-box-2">
          <h4 className="title-comments title-left">
            Comments ({comments.length})
          </h4>
        </div>
        <ul className="list-comments">
          {comments.map((c) => (
            <li key={`blog_comment__${c.idPostComment}`}>
              <div className="comment-avatar">
                <img
                  className="img-fluid rounded b-shadow-a"
                  src={
                    c.user?.userProfile?.profileImageUrl
                      ? getFullUrl(c.user.userProfile.profileImageUrl)
                      : "../assets/images/user-profile-default.png"
                  }
                  alt=""
                />
              </div>
              <div className="comment-details">
                <h4 className="comment-author">
                  {c?.user?.name ? c?.user?.name : c?.user?.username}{" "}
                  {user && user.isAdmin && (
                    <img
                      className="img-edit"
                      src="../assets/images/delete.png"
                      alt=""
                      onClick={() =>
                        handleDeleteComment(c.idPostComment, c.comment)
                      }
                    />
                  )}
                </h4>
                <span>
                  <Moment format="MMM Do YYYY hh:mm A">{c.commentDate}</Moment>
                </span>
                <p style={{ whiteSpace: "pre-wrap" }}>{c.comment}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="form-comments">
        <div className="title-box-2">
          <h3 className="title-left">Leave a comment</h3>
        </div>
        {user && !user.isAdminDemo && (
          <form className="input-form">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Comment"
                    rows={8}
                    value={comment}
                    onChange={handleCommentChange}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12">
                <button
                  type="submit"
                  className="button button-a button-normal button-rouded"
                  disabled={comment === "" ? true : false}
                  onClick={handleAddComment}
                >
                  Add Comment
                </button>
              </div>
            </div>
          </form>
        )}
        {user && user.isAdminDemo && (
          <div
            style={{
              color: "red",
            }}
          >
            Admin Demo Account
          </div>
        )}
        {!user && (
          <div>
            <p>
              <Link to={{ pathname: "/login", state: { from: location } }}>
                Login
              </Link>
            </p>
          </div>
        )}
      </div>
    </BoxContent>
  );
};

export default PostComments;
