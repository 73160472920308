import React from "react";

const DeleteConfirmBox: React.FC<{
  handleDelete: any;
  disableDeleteButton: boolean | undefined;
}> = ({ handleDelete, disableDeleteButton = false }) => {
  return (
    <div className="modal fade" id="confirm" tabIndex={-1} role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Delete
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">Are you sure?</div>
          <div className="modal-footer input-form">
            <button
              type="button"
              className="button button-a button-normal button-rouded"
              data-bs-dismiss="modal"
              onClick={handleDelete}
              disabled={disableDeleteButton}
            >
              Delete
            </button>
            <button
              type="button"
              className="button button-a button-normal button-rouded btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmBox;
