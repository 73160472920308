import React from "react";
import SideBar from "./SideBar";
import Users from "./Users";
import Posts from "./Posts";
import Tags from "./Tags";
import { Route, Switch } from "react-router-dom";
import Content from "../common/Content";
import Admin from "./Admin";
import PostEdit from "./PostEdit";
import UploadFiles from "./UploadFiles";
import UserEdit from "./UserEdit";
import UploadFile from "./UploadFile";

function AppAdmin(props: any) {
  return (
    <Content>
      <div className="d-flex">
        <div>
          <SideBar />
        </div>
        <div className="flex-fill">
          <Switch>
            <Route path="/admin/posts/:id" component={PostEdit} />
            <Route path="/admin/posts" component={Posts} />
            <Route path="/admin/tags" component={Tags} />
            <Route path="/admin/upload-files" component={UploadFiles} />
            <Route path="/admin/upload-file" component={UploadFile} />
            <Route path="/admin/users/:id" component={UserEdit} />
            <Route path="/admin/users" component={Users} />
            <Route path="/admin" component={Admin} />
          </Switch>
        </div>
      </div>
    </Content>
  );
}

export default AppAdmin;
