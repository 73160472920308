import User from "../models/user";
import httpService from "./httpService";

const baseEndpoint = "/users";
const getUsersEndpoint = "/";
const getUserByIdEndpoint = "/getUserById";
const getRolesEndpoint = "/getRoles";
const updateUserEndpoint = "/updateUser";
const createUserEndpoint = "/createUser";
const deleteUserEndpoint = "/deleteUser";

const getUsers = () => {
  return httpService.get(baseEndpoint + getUsersEndpoint);
};

const getUserById = (idUser: number) => {
  return httpService.get(
    baseEndpoint + getUserByIdEndpoint + `?idUser=${idUser}`
  );
};

const getRoles = () => {
  return httpService.get(baseEndpoint + getRolesEndpoint);
};

const updateUser = (user: User) => {
  return httpService.post(baseEndpoint + updateUserEndpoint, user);
};

const createUser = (user: User) => {
  return httpService.post(baseEndpoint + createUserEndpoint, user);
};

const deleteUser = (idUser: User) => {
  return httpService.post(baseEndpoint + deleteUserEndpoint, idUser);
};

const exportObject = {
  getUsers,
  getUserById,
  getRoles,
  updateUser,
  createUser,
  deleteUser,
};

export default exportObject;
