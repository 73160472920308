import React, { useEffect } from "react";
import Typed from "typed.js";

import BackgroundImage from "../assets/images/intro-bg.jpg";

const Home: React.FC<any> = (props) => {
  useEffect(() => {
    starTyped();
  }, []);

  function starTyped() {
    let typed_strings =
      document.getElementsByClassName("text-slider-items")[0].textContent;
    new Typed(".text-slider", {
      strings: typed_strings?.split(","),
      typeSpeed: 80,
      loop: true,
      backDelay: 1100,
      backSpeed: 30,
    });
  }

  return (
    <div
      id="home"
      className="intro route bg-image"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="overlay-itro"></div>
      <div className="intro-content display-table">
        <div className="table-cell">
          <div className="container">
            <h1 className="intro-title mb-4">I am Dan Dobriceanu</h1>
            <p className="intro-subtitle">
              <span className="text-slider-items">
                DOBRO Solutions Owner,Software Consultant,Software
                Architect,Software Developer,Full Stack Developer,Web
                Developer,Mobile Developer,Desktop Developer
              </span>
              <strong className="text-slider"></strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
