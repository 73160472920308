import React from "react";

const FormInput: React.FC<{
  name: string;
  placeholder: string;
  error: string;
  inputType: string;
  isPassword?: boolean;
  useFullWidth?: boolean;
  [rest: string]: any;
}> = ({
  name,
  placeholder,
  error,
  inputType,
  isPassword = false,
  useFullWidth = true,
  ...rest
}) => {
  return (
    <div className={useFullWidth ? "col-md-12" : ""}>
      <div className="form-group">
        <input
          type={!isPassword ? "text" : "password"}
          name={name}
          className={"form-control " + (error ? "is-invalid" : "")}
          id={name}
          placeholder={placeholder}
          {...rest}
        />
        <div className="invalid-feedback">{error}</div>
      </div>
    </div>
  );
};

export default FormInput;
