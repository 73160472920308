import { create } from "apisauce";

//const prodBaseUrl = "https://dandobriceanu.com";
const prodBaseUrl = "https://dandobriceanu.com";
const devBaseUrl = "https://localhost:7171";

const prodApiBaseUrl = prodBaseUrl + "/api";
const devApiBaseUrl = devBaseUrl + "/api";

const isProd = true;

var authToken: string | undefined;

const httpService = create({
  baseURL: isProd ? prodApiBaseUrl : devApiBaseUrl,
});

httpService.addAsyncRequestTransform(async (request) => {
  if (!authToken) {
    return;
  }
  request.headers["Authorization"] = authToken;
});

export function setToken(token: string | undefined) {
  authToken = token;
}

export function getBaseUrl() {
  return isProd ? prodBaseUrl : devBaseUrl;
}

export function getFullUrl(url: string) {
  if (url && url.startsWith("/")) {
    return getBaseUrl() + url;
  }
  return url;
}

export default httpService;
