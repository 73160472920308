import React, { useEffect, useState } from "react";
import PostService from "../services/postService";
import PostView from "./PostView";
import { getFullUrl } from "../services/httpService";
import PostComments from "./PostComments";
import Post_ from "../models/post";

const Post: React.FC<{ match: any; history: any; location: any }> = ({
  match,
  history,
  location,
}) => {
  const postShortLink = match.params.id;
  // const content = `
  // # Thanks
  // We hope that this starter will make you want to discover Strapi in more details.

  // ## Features

  // - 2 Content types: Article, Category
  // - Permissions set to true for article and category
  // - 2 Created Articles
  // - 3 Created categories
  // - Responsive design using UIkit

  // ## Pages

  // - "/" display every articles
  // - "/article/:id" display one article
  // - "/category/:id" display articles depending on the category`;

  // const markdown = `
  //   This Markdown contains <a href="https://en.wikipedia.org/wiki/HTML">HTML</a>, and will require the <code>html-parser</code> AST plugin to be loaded, in addition to setting the <code className="prop">allowDangerousHtml</code> property to false.`;

  // const imageUrl = PostImage;
  // const title = "Onion Architecture";
  // const publishDate = "2020-08-03";

  const [idPost, setIdPost] = useState<number>(0);
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [publishDate, setPublishDate] = useState<Date>(new Date());
  const [content, setContent] = useState("");
  const [postService] = useState<PostService>(new PostService());

  useEffect(() => {
    const loadPost = async (
      postShortLink: string,
      history: any,
      isAdminEditPreview: boolean
    ) => {
      //console.log("Post.loadPost()");
      let response;
      if (isAdminEditPreview) {
        response = await postService.getPostByShortLink(postShortLink);
      } else {
        response = await postService.getPublicPostByShortLink(postShortLink);
      }
      const { ok, data: post } = response as { ok: any; data: Post_ };
      if (ok) {
        setIdPost(post.idPost);
        setTitle(post.title);
        setImageUrl(post.imageUrl);
        setPublishDate(post.publishDate);
        setContent(post.content);
        //setContent(markdown);
      } else {
        history.push("/not-found");
      }
    };

    const init = async () => {
      const isAdminEditPreview = location.search?.includes("preview");
      await loadPost(postShortLink, history, isAdminEditPreview);
    };
    init();
  }, [postService, postShortLink, history, location]);

  return (
    <>
      <PostView
        title={title}
        imageUrl={getFullUrl(imageUrl)}
        publishDate={publishDate}
        content={content}
      />
      <PostComments idPost={idPost} location="" />
    </>
  );
};

export default Post;
