import React, { useState } from "react";
import Joi from "joi";
import BoxContent from "./common/BoxContent";
import Form from "./common/Form";
import FormInput from "./common/FormInput";
import FormButton from "./common/FormButton";
import idenitityService from "../services/identityService";
import httpErrorMessage from "../services/httpServiceErrorMessage";

function ForgotPassword() {
  const [data, setData] = useState({
    username: "",
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [forgotPasswordError, setForgotPasswordError] = useState("");

  const schema = {
    username: Joi.string().required().label("Username"),
  };

  const doSubmit = () => {
    return new Promise(async (resolve) => {
      setForgotPasswordError("");
      const result = await idenitityService.forgotPassword(data.username);
      if (!result.ok) {
        setForgotPasswordError(httpErrorMessage.getErrorMessage(result));
        resolve(false);
        return;
      }
      setForgotPasswordError("");
      setData({ username: "" });
      resolve(true);
    });
  };

  return (
    <BoxContent
      id="forgotPasword"
      containerClassName="work-mf sect-pt6 route"
      useFullWidth={false}
    >
      <div>
        <div className="col-sm-12">
          <div className="title-box-2">
            <h5 className="title-left">Forgot Password</h5>
          </div>
          <div>
            <Form
              data={data}
              setData={setData}
              schema={schema}
              errors={errors}
              setErrors={setErrors}
              doSubmit={doSubmit}
              onSubmitOk={() => {}}
              submitErrorMessage={forgotPasswordError}
              submitOkMessage="Please check your email to reset your password."
            >
              <FormInput
                name="username"
                placeholder="Username"
                error={errors["username"]}
                inputType="FormInput"
              />
              <FormButton
                label="Reset Password"
                inputType="FormButton"
                disabled={false}
              />
            </Form>
          </div>
        </div>
      </div>
    </BoxContent>
  );
}

export default ForgotPassword;
