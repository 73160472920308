import "./App.css";
import React, { useCallback, useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import ReactGA from "react-ga";

import NavBar from "./components/NavBar";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Work from "./components/Work";
import Certifications from "./components/Certifications";
import Contact from "./components/Contact";
import NotFound from "./components/NotFound";
import Unauthorized from "./components/Unauthorized";
import Login from "./components/Login";
import Register from "./components/Register";
import Blog from "./components/Blog";
import Post from "./components/Post";
import AppAdmin from "./components/admin/AppAdmin";
import Logout from "./components/Logout";
import RegisterConfirmation from "./components/RegisterConfirmation";
import ForgotPassword from "./components/ForgotPassword";
import ForgotPasswordResetPassword from "./components/ForgotPasswordResetPassword";
import identityService from "./services/identityService";
import ProtectedRoute from "./components/common/ProtectedRoute";
import Profile from "./components/Profile";
import UserContext from "./context/userContext";
import Chat from "./components/Chat";
import { Tooltip } from "bootstrap";

import DefaultProfileImage from "./assets/images/user-profile-default.png";
import User from "./models/user";

interface RouteProps extends RouteComponentProps<any> {}

const App: React.FC<RouteProps> = ({ location }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isBusy, setIsBusy] = useState(false);

  const hidePreloader = useCallback(() => {
    const preloader = getPreloader();
    if (preloader) {
      preloader.style.display = "none";
      // setTimeout(() => {
      //   preloader.style.display = "none";
      // }, 3000);
    }
  }, []);

  useEffect(() => {
    //console.log("app:init()");

    const user = identityService.getCurrentUser();
    if (user?.userProfile && !user.userProfile.profileImageUrl) {
      user.userProfile.profileImageUrl = DefaultProfileImage;
    }
    setUser(user);

    hidePreloader();

    ReactGA.initialize("UA-172776094-1");

    initTooltips();
  }, [hidePreloader]);

  useEffect(() => {
    //console.log("location changed");
    ReactGA.pageview(location.pathname);
  }, [location]);

  //console.log("render");

  const initTooltips = (): void => {
    Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(
      (tooltipNode) => new Tooltip(tooltipNode)
    );
  };

  const getPreloader = (): HTMLElement | undefined => {
    return document.getElementsByClassName("preloader")[0] as HTMLElement;
  };

  const showPreloader = () => {
    const preloader = getPreloader();
    if (preloader) {
      preloader.style.display = "initial";
    }
  };

  const onPreloaderEffectEnd = () => {
    //console.log("onPreloaderEffectEnd");
    // only fadeOut css class has transition, so onPreloaderEffectEnd occurs on end - fadeOut is set if isBusy == false below
    hidePreloader();
  };

  if (isBusy) {
    showPreloader();
  }

  return (
    <>
      <NavBar user={user} />
      <UserContext.Provider value={user}>
        <main id="main" className="main">
          <Switch>
            <Route path="/about" component={About} />
            <Route path="/services" component={Services} />
            <Route path="/work" component={Work} />
            <Route path="/certifications" component={Certifications} />
            {/*<Route path="/contact" component={Contact} />*/}
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/register" component={Register} />
            <Route
              path="/register-confirmation/:accountConfirmationToken"
              component={RegisterConfirmation}
            />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route
              path="/reset-password/:resetPasswordToken"
              component={ForgotPasswordResetPassword}
            />
            <Route path="/profile" component={Profile} />
            <Route path="/blog/:id" component={Post} />
            <Route path="/blog" render={() => <Blog setIsBusy={setIsBusy} />} />
            <Route
              path="/chat"
              render={(props) => <Chat {...props} setIsBusy={setIsBusy} />}
            />
            <ProtectedRoute isAdmin={true} path="/admin" component={AppAdmin} />
            <Route path="/unauthorized" component={Unauthorized} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/" component={Home} />
            {/* <Redirect from="/" exact to="/home" /> */}
            <Redirect to="/not-found" />
          </Switch>
        </main>
      </UserContext.Provider>
      <div
        id="preloader"
        className={"preloader" + (isBusy ? " fadeIn" : " fadeOut")}
        onTransitionEnd={onPreloaderEffectEnd}
      />
    </>
  );
};

export default withRouter(App);
