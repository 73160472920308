import React from "react";
import { Redirect, Route } from "react-router-dom";
import identityService from "../../services/identityService";

const ProtectedRoute: React.FC<{
  isAdmin: boolean;
  path: string;
  component: any;
  [rest: string]: any;
}> = ({ isAdmin, path, component: Component, render, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (!identityService.getCurrentUser()) return <Redirect to="/login" />;
        if (
          isAdmin &&
          !(
            identityService.getCurrentUser()?.isAdmin ||
            identityService.getCurrentUser()?.isAdminDemo
          )
        )
          return <Redirect to="/unauthorized" />;
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
