import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import chatService from "../services/chatService";
import { getFullUrl } from "../services/httpService";
import BoxContent from "./common/BoxContent";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import UserContext from "../context/userContext";
import moment from "moment";
import { KEY_RETURN } from "keycode-js";

import SendButtonIcon from "../assets/images/send-icon.png";
import DeleteIcon from "../assets/images/delete.png";
import DefaultProfileImage from "../assets/images/user-profile-default.png";
import { Tooltip } from "bootstrap";

const Chat: React.FC<{ location: any; setIsBusy: any }> = ({
  location,
  setIsBusy,
}) => {
  const user = useContext(UserContext);

  const [messages, setMessages] = useState<any[]>([]);

  const messagesListRef = useRef<HTMLLIElement>(null);
  const messageTextareaRef = useRef<HTMLTextAreaElement>(null);
  const sendMessageButtonRef = useRef<HTMLButtonElement>(null);

  const messageTimeRefs = useRef<HTMLSpanElement[]>([]);

  const getMessage = (): string => {
    return messageTextareaRef.current?.value as string;
  };

  const setMessage = (message: string) => {
    if (messageTextareaRef.current) {
      messageTextareaRef.current.value = message;
    }
  };

  const enableSendMessageButton = useCallback((isEnabled) => {
    if (!sendMessageButtonRef.current) {
      return;
    }
    if (getMessage() === "") {
      sendMessageButtonRef.current.disabled = true;
    } else {
      sendMessageButtonRef.current.disabled = !isEnabled;
    }
  }, []);

  let listItemId = 1;

  //console.log("render");

  const handleMessageReceived = (
    name: string,
    username: string,
    profileImageUrl: string,
    message: string,
    messageDate: any
  ) => {
    const msgs = [
      ...messages,
      {
        message,
        messageDate,
        user: { name, username, userProfile: { profileImageUrl } },
      },
    ];
    setMessages(msgs);
    scollMessageListToBottom();
    //console.log("Message Received (Render)");
    //console.log(messageTimeRefs.current.length);
  };

  const scollMessageListToBottom = () => {
    if (messageTextareaRef && messagesListRef.current) {
      messagesListRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  chatService.onMessageReceived(handleMessageReceived);

  useEffect(() => {
    const init = async () => {
      setIsBusy(true);
      enableSendMessageButton(false);
      await chatService.connect();
      const { ok, data: messages } = await chatService.getMainChatMessages();
      if (ok) {
        setMessages(messages as []);
        scollMessageListToBottom();
      }
      enableSendMessageButton(true);
      setIsBusy(false);
    };
    init();
  }, [setIsBusy, enableSendMessageButton]);

  const handleSendMessage = async () => {
    var message = getMessage();
    if (message === "") {
      return;
    }
    await chatService.sendMessage(message);
    setMessage("");
  };

  const handleMessageKeyDown = async (e: any) => {
    if (e.which === KEY_RETURN && !e.shiftKey) {
      // enter key pressed
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleMessageKeyUp = async (e: any) => {
    enableSendMessageButton(true);
  };

  let currentSartDate: any;

  const getStartDate = (chatMessage: any) => {
    var msgDate = moment(chatMessage.messageDate).format("MMM Do YYYY");
    if (msgDate !== currentSartDate) {
      currentSartDate = msgDate;
      //console.log(currentSartDate);
      return currentSartDate;
    }
    //console.log("null currentStartDate");
    return null;
  };

  const handleDeleteMessage = async (
    idChatMessage: number,
    message: string
  ) => {
    if (
      window.confirm(
        `Are you sure you want to delete the message?\r\n"${message}"`
      )
    ) {
      var { ok, data } = await chatService.deleteChatMessage(idChatMessage);
      if (ok) {
        setMessage("");
        const { ok, data: messages } = await chatService.getMainChatMessages();
        if (ok) {
          setMessages(messages as []);
        }
      } else {
        alert((data as any).message);
      }
    }
  };

  return (
    <BoxContent
      id="chat"
      containerClassName="chat sect-pt6 route"
      useFullWidth={false}
    >
      <div className="header d-flex justify-content-between align-items-end">
        <h5>CHAT</h5>
        <p style={{ fontSize: "12px" }}>Messages ({messages.length})</p>
      </div>
      <div className="box-comments">
        <ul
          className="list-comments"
          style={{
            overflowY: "scroll",
          }}
        >
          {messages.map((msg) => (
            <React.Fragment key={`chat_listItem__${listItemId++}`}>
              {getStartDate(msg) && (
                <li>
                  <div className="d-flex justify-content-center dateStart">
                    <p> {currentSartDate}</p>
                  </div>
                  <div className="dateStartLine" />
                </li>
              )}
              <li ref={messagesListRef}>
                <div className="comment-avatar">
                  <img
                    className="img-fluid rounded b-shadow-a"
                    src={
                      msg.user.userProfile.profileImageUrl
                        ? getFullUrl(msg.user.userProfile.profileImageUrl)
                        : DefaultProfileImage
                    }
                    alt=""
                  />
                </div>
                <div className="comment-details">
                  <div className="d-flex align-items-center">
                    <p className="comment-author">
                      {msg.user.name ? msg.user.name : msg.user.username}
                    </p>
                    <span
                      data-key={`message_span__${listItemId++}`}
                      className="comment-date"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={moment(msg.messageDate).format(
                        "MMM Do YYYY [at] hh:mm:ss A"
                      )}
                      ref={(ref) => {
                        if (ref) {
                          //console.log("msg-ref-called");
                          let alredyAdded = false;
                          for (let existingRef of messageTimeRefs.current) {
                            if (
                              ref.getAttribute("data-key") ===
                              existingRef.getAttribute("data-key")
                            ) {
                              alredyAdded = true;
                              break;
                            }
                          }
                          if (!alredyAdded) {
                            //console.log("newRef", ref as HTMLSpanElement);
                            new Tooltip(ref);
                            messageTimeRefs.current.push(ref);
                          }
                        }
                      }}
                    >
                      <Moment format="hh:mm A">{msg.messageDate}</Moment>
                    </span>{" "}
                    {user && user.isAdmin && msg.idChatMessage && (
                      <img
                        className="img-edit"
                        src={DeleteIcon}
                        alt=""
                        onClick={() =>
                          handleDeleteMessage(msg.idChatMessage, msg.message)
                        }
                      />
                    )}
                  </div>
                  <p
                    style={{ whiteSpace: "pre-wrap" }}
                    className="comment-message"
                  >
                    {msg.message}
                  </p>
                </div>
              </li>
            </React.Fragment>
          ))}
        </ul>
      </div>
      <div className="chat-form">
        {!user && (
          <div
            style={{
              position: "absolute",
              bottom: "28px",
              left: "50%",
              zIndex: 100,
            }}
          >
            <Link to={{ pathname: "/login", state: { from: location } }}>
              Login
            </Link>
          </div>
        )}
        {user && user.isAdminDemo && (
          <div
            style={{
              position: "absolute",
              bottom: "28px",
              left: "42%",
              zIndex: 100,
              color: "red",
            }}
          >
            Admin Demo Account
          </div>
        )}
        <div className={!user || user.isAdminDemo ? "div-overlay" : ""}>
          <div className="d-flex">
            <textarea
              className="form-control"
              placeholder="Message"
              rows={1}
              //value={message} // performance issues due to large nr of elements, use ref to avoid render() on each change
              //onChange={handleMessageChange}
              onKeyDown={handleMessageKeyDown}
              onKeyUp={handleMessageKeyUp}
              ref={messageTextareaRef}
            ></textarea>
            <button
              type="submit"
              className="button button-normal button-rouded"
              // disabled={!sendMessageEnabled || !isConnected ? true : false} // avoid render with ref
              onClick={handleSendMessage}
              aria-label={"Send Message"}
              data-balloon-pos="up"
              ref={sendMessageButtonRef}
            >
              <img className="send-message-icon" src={SendButtonIcon} alt="" />
            </button>
          </div>
        </div>
      </div>
    </BoxContent>
  );
};

export default Chat;
