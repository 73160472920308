import React from "react";
import TitleContent from "./common/TitleContent";

import CertifCSharpImage from "../assets/images/certification-csharp.png";
import CertifMcsdImage from "../assets/images/certification-mscd.png";
import CertifAzureImage from "../assets/images/certification-azure.png";
import CertifMcsaImage from "../assets/images/certification-mcsa-web-apps.png";
import CertifAspNetImage from "../assets/images/certification-asp-net.png";
import CertifHtmlJsCssImage from "../assets/images/certification-html-js-css.png";

const certifications = [
  {
    image: CertifCSharpImage,
    title: "Microsoft Certified Professional C#",
    date: "May 2020",
    details: "MCP 70-483, Credential ID: H016-0360",
    issuedBy: "Microsoft",
    link: "https://www.youracclaim.com/badges/0c626517-389a-4b50-99f2-7d757e20a7a3",
  },
  {
    image: CertifMcsdImage,
    title: "Microsoft Certified Solutions Developer App Builder - MCSD",
    date: "Oct 2019",
    details: "MCSD App Builder, Credential ID: H249-3346",
    issuedBy: "Microsoft",
    link: "https://www.youracclaim.com/badges/4c6f827b-116c-429a-9946-2d52a69ba231",
  },
  {
    image: CertifAzureImage,
    title: "Microsoft Certified Professional Azure and Web Services",
    date: "Oct 2019",
    details: "MCP 70-487, Credential ID: H249-3346",
    issuedBy: "Microsoft",
    link: "https://www.youracclaim.com/badges/bf797f1a-a794-497a-aa15-49358f66e836",
  },
  {
    image: CertifMcsaImage,
    title: "Microsoft Certified Solutions Associate Web Applications - MCSA",
    date: "Sep 2019",
    details: "MCSA Web Applications, Credential ID: H223-4667",
    issuedBy: "Microsoft",
    link: "https://www.youracclaim.com/badges/aaa4aba3-b1b9-49a1-b310-e624baa08992",
  },
  {
    image: CertifAspNetImage,
    title: "Microsoft Certified Professional ASP.NET MVC Web Applications",
    date: "Sep 2019",
    details: "MCP 70-486, Credential ID: H223-4667",
    issuedBy: "Microsoft",
    link: "https://www.youracclaim.com/badges/fcb105f3-328a-4348-8949-0c5bc81ddef1",
  },
  {
    image: CertifHtmlJsCssImage,
    title: "Microsoft Certified Professional HTML5, JavaScript and CSS3",
    date: "Jan 2019",
    details: "MCP 70-480, Credential ID: H016-0360",
    issuedBy: "Microsoft",
    link: "https://www.youracclaim.com/badges/7fe0fee7-9e94-4578-95ea-cf4928c77a07",
  },
];

function Certifications() {
  return (
    <TitleContent
      id="certifications"
      title="Certifications"
      subtitle=""
      containerClassName="certifications-mf sect-pt6 route"
      showBox
    >
      <div className="box-comments certifications">
        <ul className="list-comments">
          {certifications.map((certification) => (
            <li key={certification.title}>
              <div className="comment-image">
                <img src={certification.image} alt="" />
              </div>
              <div className="comment-details">
                <h4 className="comment-author">{certification.title}</h4>
                <span>{certification.date}</span>
                <p>{certification.issuedBy}</p>
                <p>{certification.details}</p>
                <a
                  href={certification.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Credentials
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </TitleContent>
  );
}

export default Certifications;
