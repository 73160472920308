import httpService from "./httpService";

const baseEndpoint = "/statistics";
const getMainChatMessagesPerDayEndpoint = "/getMainChatMessagesPerDay";
const getPostCommentsCountEndpoint = "/getPostCommentsCount";

const getMainChatMessagesPerDay = () => {
  return httpService.get(baseEndpoint + getMainChatMessagesPerDayEndpoint);
};

const getPostCommentsCount = () => {
  return httpService.get(baseEndpoint + getPostCommentsCountEndpoint);
};

const exportObject = {
  getMainChatMessagesPerDay,
  getPostCommentsCount,
};

export default exportObject;
