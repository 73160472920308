import React from "react";

const FormCheckbox: React.FC<{
  name: string;
  label: string;
  error: string;
  inputType: string;
  visible?: boolean;
  [rest: string]: any;
}> = ({ name, label, error, inputType, visible = true, ...rest }) => {
  return (
    <div
      className="col-md-12"
      style={{ display: visible ? "initial" : "none" }}
    >
      <div className="form-group">
        <input type="checkbox" name={name} id={name} {...rest} />
        <label className="form-check-label checkbox-label" htmlFor={name}>
          {label}
        </label>
        <div className="validate">{error}</div>
      </div>
    </div>
  );
};

export default FormCheckbox;
