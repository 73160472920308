import React from "react";
import BoxContent from "./common/BoxContent";
import ProfileImage from "../assets/images/profile.jpg";

const skills = [
  "C#",
  ".NET Core",
  "ASP.NET Core",
  "Entity Framework Core",
  "SQL",
  "REST",
  "WebAPI",
  "SignalR",
  "gRPC",
  "Angular",
  "React",
  "React Native",
  "TypeScript",
  "JavaScript",
  "HTML",
  "CSS",
  "Bootstrap",
  "WordPress",
  "WordPress Plugins Dev",
  "WPF",
  "Multithreading",
  "TPL",
  "Azure",
];

const About: React.FC<{ props: any }> = (props: any) => {
  return (
    <BoxContent
      id="about"
      containerClassName="sect-pt6 route"
      useFullWidth={false}
    >
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-sm-6 col-md-5">
              <div className="about-img">
                <img
                  src={ProfileImage}
                  width="150"
                  height="150"
                  className="img-fluid rounded b-shadow-a"
                  alt=""
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-7">
              <div className="about-info">
                <p>
                  <span className="title-s">Name: </span>{" "}
                  <span>Dan Dobriceanu</span>
                </p>
                <p>
                  <span className="title-s">Profile: </span>{" "}
                  <span>Full Stack Developer</span>
                </p>
                <p>
                  <span className="title-s">Email: </span>{" "}
                  <span>ddobriceanu@gmail.com</span>
                </p>
                <p>
                  <span className="title-s">Linkedin: </span>{" "}
                  <span>/in/ddobriceanu</span>
                </p>
                <p>
                  <span className="title-s">Twitter: </span>{" "}
                  <span>@dandobriceanu</span>
                </p>
              </div>
            </div>
          </div>
          <div className="skill-mf">
            <p className="title-s">Skills</p>
            {skills.map((skill) => (
              <button key={skill} className="btn btn-primary disabled skill">
                {skill}
              </button>
            ))}
          </div>
        </div>
        <div className="col-md-6">
          <div className="about-me pt-4 pt-md-0">
            <div className="title-box-2">
              <h5 className="title-left-underline">About me</h5>
            </div>
            <p className="lead">
              Professional with 17+ years of experience in Professional Software
              Development, Software Architecture and Application Lifecycle
              Management.
            </p>
            <p className="lead">
              True believer that our only limitations are the ones we set in our
              own minds.
            </p>
            <p className="lead">
              Passionate about technology and always ahead of time :)
            </p>
          </div>
        </div>
      </div>
    </BoxContent>
  );
};

export default About;
