const getErrorMessage = (httpResult: any) => {
  var errorMessage = "An error occured.";
  if (!httpResult.data) {
    return errorMessage;
  }
  if (httpResult.data.message) {
    return httpResult.data.message;
  }
  if (httpResult.data.title) {
    console.log("Error", httpResult.data);
    return httpResult.data.title;
  }
  return httpResult.data;
};

const exportObject = {
  getErrorMessage,
};

export default exportObject;
