import React from "react";
import TitleContent from "./common/TitleContent";
import ServiceCard from "./common/ServiceCard";

const services = [
  {
    title: "IT Consulting",
    description: "Complete IT Solutions",
    // iconClassName: 'ion-monitor',
    iconClassName: "fa fa-lightbulb-o",
  },
  {
    title: "Development",
    description: "Software Architecture and Development",
    //iconClassName: 'ion-stats-bars',
    iconClassName: "fa fa-bar-chart",
  },
  {
    title: "Cloud",
    description: "Cloud Processing and Infrastructure",
    // iconClassName: 'ion-cloud',
    iconClassName: "fa fa-cloud",
  },
  {
    title: "Web Development",
    description: "Web Apps and Web Services Development",
    // iconClassName: 'ion-code-working',
    iconClassName: "fa fa-code",
    height: 20,
  },
  {
    title: "Mobile Development",
    description: "Mobile Applications Development",
    // iconClassName: 'ion-android-phone-portrait',
    iconClassName: "fa fa-mobile",
  },
  {
    title: "Desktop Development",
    description: "Desktop Application Development",
    //iconClassName: 'ion-android-desktop',
    iconClassName: "fa fa-desktop",
  },
];

function Services(props: any) {
  return (
    <TitleContent
      id="services"
      title="Services"
      subtitle="Complete Software Consulting and Development Solutions"
      containerClassName="services-mf sect-pt6 route"
    >
      {services.map((service) => (
        <ServiceCard
          key={service.title}
          title={service.title}
          description={service.description}
          iconClassName={service.iconClassName}
        />
      ))}
    </TitleContent>
  );
}

export default Services;
