import React from "react";

const FormButton: React.FC<{
  label: string;
  disabled: boolean;
  inputType: string;
  center?: boolean;
}> = ({ label, disabled, inputType, center = true }) => {
  return (
    <div className={"col-md-12" + (center ? " text-center" : "")}>
      <div className="form-group">
        <button
          type="submit"
          className="button button-a button-normal button-rouded"
          disabled={disabled}
        >
          {label}
        </button>
      </div>
    </div>
  );
};

export default FormButton;
