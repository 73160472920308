import React, { useEffect, useState } from "react";

const FileUpload: React.FC<{
  isImage?: boolean;
  imageUrl: string;
  imageWidth: number | undefined;
  imageHeight: number | undefined;
  onFileUpload: any;
  onFileSelected: any;
  disabled?: boolean;
}> = ({
  isImage = false,
  imageUrl,
  imageWidth,
  imageHeight,
  onFileUpload,
  onFileSelected,
  disabled = false,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const [displayImageUrl, setDisplayImageUrl] = useState("");

  useEffect(() => {
    if (isImage) {
      setDisplayImageUrl(imageUrl);
    }
  }, [isImage, imageUrl]);

  const handleImageChangeSubmit = async (e: any) => {
    e.preventDefault();

    if (!selectedFile) {
      return;
    }

    onFileUpload(selectedFile);

    // const formData = new FormData();
    // formData.append("file", selectedImage);
    //console.log(selectedImage);
    // var result = await profileService.upateProfileImage(formData);
    // if (!result.ok) {
    //   alert("upateProfileImage error");
    // }
  };

  const handleBrowse = ({ target: { files } }: { target: any }) => {
    if (!files[0]) {
      return;
    }

    setSelectedFile(files[0]);
    if (onFileSelected) {
      onFileSelected(files[0]);
    }

    if (isImage) {
      var url = URL.createObjectURL(files[0]);
      setDisplayImageUrl(url);
    }

    //const item = { target: { name: "file", value: files[0] } };
    //URL.createObjectURL(fileToEdit.file)
    //console.log(url);
  };

  return (
    <form className="input-form" onSubmit={handleImageChangeSubmit}>
      {isImage && (
        <p>
          <img
            src={displayImageUrl}
            width={imageWidth}
            height={imageHeight}
            className="img-fluid rounded b-shadow-a"
            alt=""
          />
        </p>
      )}
      <p>
        <span className="custom-file">
          <input
            type="file"
            className="form-control"
            multiple={false}
            onChange={handleBrowse}
            style={{ fontSize: 12 }}
          />
        </span>
      </p>
      <p></p>
      <button
        type="submit"
        className="button button-a button-normal button-rouded"
        disabled={disabled}
      >
        Upload
      </button>
    </form>
  );
};

export default FileUpload;
