import React, { useEffect, useState } from "react";
import Joi from "joi";
import BoxContent from "../common/BoxContent";
import Form from "../common/Form";
import FormInput from "../common/FormInput";
import FormButton from "../common/FormButton";
import userService from "../../services/userService";
import FormCheckbox from "../common/FormCheckbox";
import httpErrorMessage from "../../services/httpServiceErrorMessage";
import User from "../../models/user";
import Role from "../../models/role";

const UserEdit: React.FC<{ match: any }> = ({ match }) => {
  const idUser = match.params.id;
  const [isNew, setIsNew] = useState(true);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    isConfirmed: true,
    isBlocked: false,
    isChangePassword: isNew ? true : false,
    password: "",
    reenterPassword: "",
  });

  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState("User");

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [submitError, setSubmitError] = useState("");

  const schema = {
    firstName: Joi.string().allow("", null),
    lastName: Joi.string().allow("", null),
    username: Joi.string().required().label("Username"),
    email: Joi.string()
      .required()
      .email({ tlds: { allow: false } })
      .label("Email"),
    isConfirmed: Joi.boolean(),
    isBlocked: Joi.boolean(),
    isChangePassword: Joi.boolean(),
    password: Joi.string().when("isChangePassword", {
      is: Joi.boolean().valid(true),
      then: Joi.required().label("Password"),
      otherwise: Joi.allow("", null),
    }),
    reenterPassword: Joi.string().when("isChangePassword", {
      is: Joi.boolean().valid(true),
      then: Joi.required().label("Reenter Password"),
      otherwise: Joi.allow("", null),
    }),
  };

  useEffect(() => {
    const init = async () => {
      if (idUser !== "new") {
        setIsNew(false);
        const { ok, data: user } = await userService.getUserById(idUser);
        if (ok) {
          setUserFromModel(user);
        }
      }
      const { ok: ok2, data: roles } = await userService.getRoles();
      if (ok2) {
        setRoles(roles as []);
      }
    };
    init();
  }, [idUser]);

  const setUserFromModel = (user: any) => {
    setData({
      firstName: user.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      username: user.username,
      email: user.email,
      isConfirmed: user.isConfirmed,
      isBlocked: user.isBlocked,
      isChangePassword: false,
      password: "",
      reenterPassword: "",
    });
    setRole(user.role.roleType);
  };

  const getUserFromView = () => {
    console.log("isChangePassword", data.isChangePassword);
    console.log("password", data.password);
    const user = new User();
    user.idUser = !isNew ? idUser : 0;
    user.firstName = data.firstName;
    user.lastName = data.lastName;
    user.username = data.username;
    user.email = data.email;
    user.isConfirmed = data.isConfirmed;
    user.isBlocked = data.isBlocked;
    user.password = data.isChangePassword ? data.password : "";
    user.role = new Role();
    user.role.roleType = role;
    return user;
  };

  const handleRoleChange = (event: any) => {
    setRole(event.target.value);
  };

  const doSubmit = () => {
    return new Promise(async (resolve) => {
      setSubmitError("");
      var user = getUserFromView();
      if (isNew) {
        const result = await userService.createUser(user);
        if (!result.ok) {
          setSubmitError(httpErrorMessage.getErrorMessage(result));
          resolve(false);
          return;
        }
      } else {
        const result = await userService.updateUser(user);
        if (!result.ok) {
          setSubmitError(httpErrorMessage.getErrorMessage(result));
          resolve(false);
          return;
        }
      }
      setSubmitError("");
      // setData({ username: "", password: "", reenterPassword: "", email: "" });
      resolve(true);
    });
  };

  return (
    <BoxContent id="userEdit" containerClassName="" useFullWidth={true}>
      <div>
        <div>
          <div className="title-box-2">
            <h5 className="title-left">User</h5>
          </div>
          <div>
            <Form
              data={data}
              setData={setData}
              schema={schema}
              errors={errors}
              setErrors={setErrors}
              doSubmit={doSubmit}
              onSubmitOk={() => {}}
              submitErrorMessage={submitError}
              submitOkMessage="Saved"
            >
              <FormInput
                name="firstName"
                placeholder="First Name"
                error={errors["firstName"]}
                inputType="FormInput"
              />
              <FormInput
                name="lastName"
                placeholder="Last Name"
                error={errors["lastName"]}
                inputType="FormInput"
              />
              <FormInput
                name="username"
                placeholder="Username"
                error={errors["username"]}
                inputType="FormInput"
              />
              <FormInput
                name="email"
                placeholder="Email"
                error={errors["email"]}
                inputType="FormInput"
              />
              <FormCheckbox
                name="isConfirmed"
                label="Is Confirmed"
                error={errors["isConfirmed"]}
                inputType="FormInputCheckbox"
              />
              <FormCheckbox
                name="isBlocked"
                label="Is Blocked"
                error={errors["isBlocked"]}
                inputType="FormInputCheckbox"
              />
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="roleSelect">Role</label>
                  <select
                    className="form-control"
                    id="roleSelect"
                    value={role}
                    onChange={handleRoleChange}
                  >
                    {roles.map((r) => (
                      <option key={`role__${r}`}>{r}</option>
                    ))}
                  </select>
                </div>
              </div>
              <FormCheckbox
                name="isChangePassword"
                label="Change Password"
                error={errors["isChangePassword"]}
                inputType="FormInputCheckbox"
                visible={!isNew}
              />
              <FormInput
                name="password"
                placeholder="Password"
                error={errors["password"]}
                inputType="FormInput"
                isPassword
                //   useFullWidth={false}
                //   style={{ width: "300px" }}
                disabled={!data.isChangePassword}
              />
              <FormInput
                name="reenterPassword"
                placeholder="Reenter Password"
                error={errors["reenterPassword"]}
                inputType="FormInput"
                isPassword
                //   useFullWidth={false}
                //   style={{ width: "300px", marginLeft: "10px" }}
                disabled={!data.isChangePassword}
              />
              <div className=""></div>
              <FormButton
                label="Save"
                inputType="FormButton"
                center={false}
                disabled={false}
              />
            </Form>
          </div>
        </div>
      </div>
    </BoxContent>
  );
};

export default UserEdit;
