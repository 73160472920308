import React from "react";

const BoxContent: React.FC<{
  id: string;
  containerClassName: string;
  children: any;
  useFullWidth: boolean;
  isSmall?: boolean;
}> = ({ id, containerClassName, children, useFullWidth, isSmall = false }) => {
  return (
    <section id={id} className={containerClassName}>
      <div className={useFullWidth ? "container-fluid" : "container"}>
        <div className="row">
          {!isSmall && (
            <div className="col-sm-12">
              <div className="box-shadow-full">{children}</div>
            </div>
          )}
          {isSmall && (
            <div className="col-sm-10" style={{ marginLeft: 13 }}>
              <div className="box-shadow-full">{children}</div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default BoxContent;
