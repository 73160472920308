import React, { useState } from "react";
import Joi from "joi";
import BoxContent from "./common/BoxContent";
import Form from "./common/Form";
import FormInput from "./common/FormInput";
import FormButton from "./common/FormButton";
import identityService from "../services/identityService";
import httpErrorMessage from "../services/httpServiceErrorMessage";

const ForgotPasswordResetPassword: React.FC<{ match: any }> = ({ match }) => {
  const resetPasswordToken = match.params.resetPasswordToken;
  const [data, setData] = useState({
    password: "",
    reenterPassword: "",
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [resetPasswordError, setResetPasswordError] = useState("");

  const schema = {
    password: Joi.string().required().label("New Password"),
    reenterPassword: Joi.string().required().label("Reenter New Password"),
  };

  const doSubmit = () => {
    return new Promise(async (resolve) => {
      setResetPasswordError("");
      const result = await identityService.resetPassword(
        resetPasswordToken,
        data.password
      );
      if (!result.ok) {
        setResetPasswordError(httpErrorMessage.getErrorMessage(result));
        resolve(false);
        return;
      }
      setResetPasswordError("");
      setData({ password: "", reenterPassword: "" });
      resolve(true);
    });
  };

  return (
    <BoxContent
      id="forgotPasswordReset"
      containerClassName="work-mf sect-pt6 route"
      useFullWidth={false}
    >
      <div>
        <div className="col-sm-12">
          <div className="title-box-2">
            <h5 className="title-left">Change Password</h5>
          </div>
          <div>
            <Form
              data={data}
              setData={setData}
              schema={schema}
              errors={errors}
              setErrors={setErrors}
              doSubmit={doSubmit}
              onSubmitOk={() => {}}
              submitErrorMessage={resetPasswordError}
              submitOkMessage="Your password was changed."
            >
              <FormInput
                name="password"
                placeholder="New Password"
                error={errors["password"]}
                inputType="FormInput"
                isPassword
              />
              <FormInput
                name="reenterPassword"
                placeholder="Reenter New Password"
                error={errors["reenterPassword"]}
                inputType="FormInput"
                isPassword
              />
              <FormButton
                label="Change Password"
                inputType="FormButton"
                disabled={false}
              />
            </Form>
          </div>
        </div>
      </div>
    </BoxContent>
  );
};

export default ForgotPasswordResetPassword;
