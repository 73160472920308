import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";
import statisticsService from "../../../services/statisticsService";

function ChartPosts(props: any) {
  const chartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let chart: Chart;

    var COLORS = [
      // "#4dc9f6",
      // "#f67019",
      "#f53794",
      "#537bc4",
      "#acc236",
      "#166a8f",
      "#00a950",
      "#58595b",
      "#8549ba",
    ];

    const colorAtIndex = (index: number) => {
      return COLORS[index % COLORS.length];
    };

    const init = async () => {
      const myChartRef = (chartRef.current as any)?.getContext("2d");

      //   const labels = ["Day1", "Day2", "Day3", "Day4", "Day5", "Day6"];
      //   const data = [10, 20, 30, 40, 10, 20];

      //let chartLabel = "Post Comments #";
      let labels = [];
      let data = [];
      let color = [];

      var { ok, data: _chartData } =
        await statisticsService.getPostCommentsCount();
      if (ok) {
        const chartData = _chartData as any;
        labels = chartData.labels;
        data = chartData.data;
        for (let i = 0; i < labels.length; i++) {
          color.push(colorAtIndex(i));
        }
      }

      Chart.register(...registerables);

      // pie chart
      chart = new Chart(myChartRef, {
        type: "pie",
        data: {
          //Bring in data
          labels: labels,
          datasets: [
            {
              backgroundColor: color,
              // borderColor: "rgb(255, 99, 132)"
              data: data,
            },
          ],
        },
        options: {
          responsive: true,
        },
      });
    };
    init();
    return () => {
      //console.log("pie-chart-destory");
      chart.destroy();
    };
  }, []);

  return (
    <div style={{ width: "20%" }}>
      <canvas ref={chartRef} />
    </div>
  );
}

export default ChartPosts;
