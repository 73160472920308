import { link } from "joi";
import React from "react";

const WorkCard: React.FC<{
  projectName: string;
  projectType: string;
  description: string;
  technologies: string;
  role: string;
  imageSrc: string;
  techImageSrc?: string;
  link: string | undefined;
}> = ({
  projectName,
  projectType,
  description,
  technologies,
  role,
  imageSrc,
  techImageSrc,
  link,
}) => {
  return (
    <div className="col-md-4 work-card-container">
      <div className="work-box">
        <div className="work-img">
          <img src={imageSrc} alt="" className="img-fluid" />
        </div>
        {/* <div className="imgBoxDiv"></div> */}
        {description && (
          <div className="work-content-text-over-card-container">
            <p className="work-content-text-over-card-text">{description}</p>
          </div>
        )}
        <div className="work-card-content">
          <h2 className="w-title">{projectName}</h2>
          <h6>{projectType}</h6>
          <p />
          <p>
            <strong>Technologies Used :</strong> {technologies}
          </p>
          {role && (
            <p>
              <strong>Role :</strong> {role}
            </p>
          )}
          {techImageSrc && (
            <div>
              <img
                src={techImageSrc}
                alt=""
                style={{ height: 30, position: "absolute", top: 15, right: 10 }}
              />
            </div>
          )}
          {link && (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              title="Link"
            >
              Link
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkCard;
