import React from "react";
import { Link } from "react-router-dom";
import Post from "../../models/post";

import ProfileImage from "../../assets/images/profile.jpg";

const PostCard: React.FC<{ post: Post; imageClassName: string }> = ({
  post,
  imageClassName,
}) => {
  return (
    <Link
      className="text-reset text-decoration-none"
      to={`/blog/${post.shortLink}`}
    >
      <div>
        <div>
          <img
            className={imageClassName}
            src={post.imageUrl}
            alt={post.imageUrl}
          />
        </div>
        <div className="blog-card-body">
          <p className="text-uppercase">{post.categoryName}</p>
          <p className="blog-card-body-text">{post.title}</p>
          <div>
            <img src={ProfileImage} alt="" className="avatar rounded-circle" />
            <span className="author">Dan Dobriceanu</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PostCard;
