import React from "react";
import TitleContent from "./common/TitleContent";
import WorkCard from "./common/WorkCard";

import ProjectMySiteImage from "../assets/images/project-my-site.jpg";
import ProjectRoadsViewerImage from "../assets/images/project-roads-viewer.jpg";
import ProjectRoadsViewerHtml5Image from "../assets/images/project-roads-viewer-html5.jpg";
import ProjectRoadsPublisherImage from "../assets/images/project-roads-publisher.jpg";
import ProjectRoadsDatabaseUpdaterImage from "../assets/images/project-roads-database-updater.jpg";
import ProjectRoadsImagesIntegrityCheckerImage from "../assets/images/project-roads-images-integrity-checker.jpg";
import ProjectGitMigrationAutomatedBuildsImage from "../assets/images/project-git-migration-automated-builds.jpg";
import ProjectPalletStorageControlSystemImage from "../assets/images/project-pallet-storage-control-system.jpg";
import ProjectOnlineBackupWebPortalImage from "../assets/images/project-online-backup-web-portal.jpg";
import ProjectParentalControlImage from "../assets/images/project-parental-control.jpg";
import ProjectSecurityManagementConsoleImage from "../assets/images/project-security-management-console.jpg";
import ProjectConstructionCompanyManagementImage from "../assets/images/project-construction-company-management.jpg";
import ProjectAnalyticsAppImage from "../assets/images/project-analytics-app.jpg";
import ProjectEcommerceForPoolToysCandlesImage from "../assets/images/project-e-commerce-for-pool-toys-candles.jpg";
import ProjectOnlineTicketsImage from "../assets/images/project-online-tickets.jpg";
import ProjectMassageInvitationImage from "../assets/images/project-massage-invitation.jpg";
import ProjectFlexInfoWindowImage from "../assets/images/project-flex-info-window.jpg";
import ProjectAthleteAcademyImage from "../assets/images/project-athlete-academy.jpg";
import ProjectBusinessMapManagerImage from "../assets/images/project-business-map-manager.jpg";
import ProjectMapRoutePlannerImage from "../assets/images/project-map-route-planner.jpg";
import Project3DBusinessScenarioModellingImage from "../assets/images/project-3d-business-scenario-modelling.jpg";
import ProjectIndustrialPhotoPrintingImage from "../assets/images/project-industrial-photo-printing.jpg";

import ReactLogo from "../assets/images/react_logo.png";
import AngularLogo from "../assets/images/angular_logo.png";

const projects = [
  {
    projectName: "My Website React Version (this)",
    projectType: "Web App",
    description: "",
    technologies: "React, TypeScript, Boostrap, ASP.NET Core, Azure",
    role: "",
    imageSrc: ProjectMySiteImage,
    techImageSrc: ReactLogo,
    link: "https://dandobriceanu.com",
  },
  {
    projectName: "My Website Angular Version",
    projectType: "Web App",
    description: "",
    technologies: "Angular, TypeScript, Bootstrap, ASP.NET Core, Azure",
    role: "",
    imageSrc: ProjectMySiteImage,
    techImageSrc: AngularLogo,
    link: "https://dandobriceanu2.azurewebsites.net",
  },
  {
    projectName: "Roads Viewer",
    projectType: "Web App",
    description:
      "An app having synchronized views of road camera images, road condition measurements, location information, GPS positions, map, reports and data playback.",
    technologies:
      "C#, .NET Framework, Silverlight, Telerik Controls, ASP.NET, ASP.NET MVC, WCF, REST, WebAPI, SignalR, JavaScript, JQuery, SQL Server, ADO.NET, Entity Framework, GIS, Automated Build, Amazon Web Services (AWS), Redis",
    role: "Architect, Team Lead, Lead Developer",
    imageSrc: ProjectRoadsViewerImage,
  },
  {
    projectName: "Roads Viewer HTML 5",
    projectType: "Web App",
    description:
      "An app with a map, front camera, pavement camera, progressive images loading and custom image playback method with image preloaders for thumbnails.",
    technologies:
      "Angular 2+, TypeScript, JavaScript, ES6, Node.js, LeafletJS, Emitter, Observables, Promises, Bower, Typings",
    role: "Architect, Team Lead, Lead Developer",
    imageSrc: ProjectRoadsViewerHtml5Image,
  },
  {
    projectName: "Roads Publisher",
    projectType: "Desktop App",
    description:
      "An ETL app to publish road data, like (images, GPS, etc.) taken from road report and based on different parameters transform the data to the required format.",
    technologies:
      "C#, .NET Framework, WPF, Windows Forms, MVVM, NUnit, SQL Server, Bulk Copy, Multithreading, Task Parallel Library (TPL), Automated Build",
    role: "Architect, Lead Developer",
    imageSrc: ProjectRoadsPublisherImage,
  },
  {
    projectName: "Roads Database Updater",
    projectType: "Desktop App",
    description:
      "An app for upgrading the roads database to the latest version based on incremental scrips and custom C# code running between different versions.",
    technologies:
      "C#, .NET Framework, WPF, Windows Forms, MVVM, NUnit, SQL Server, Transactions, Multithreading, Task Parallel Library (TPL), Automated Build",
    role: "Architect, Lead Developer",
    imageSrc: ProjectRoadsDatabaseUpdaterImage,
  },
  {
    projectName: "Road Images Integrity Checker",
    projectType: "Desktop App",
    description:
      "An app used to check image web folders specified in the database and their physical mappings to create reports for the missing images needed to be uploaded.",
    technologies:
      "C#, .NET Framework, SQL Server, File System, XML, XPath, NUnit",
    role: "Architect, Lead Developer",
    imageSrc: ProjectRoadsImagesIntegrityCheckerImage,
  },
  {
    projectName: "GIT Migration, Automated Builds",
    projectType: "Application Lifecycle Management",
    description:
      "Code migration from SVN to GIT using best practices based on Git Flow workflow and creation of multiple Automated Builds for different projects.",
    technologies:
      "GIT, Source Control, Automated Builds, Versioning, Change-log, Deployment, Continuous Integration, Jenkins, Custom GIT Extension Scripts, GIT Flow Workflow",
    role: "Architect",
    imageSrc: ProjectGitMigrationAutomatedBuildsImage,
  },
  {
    projectName: "Pallet Storage Control System",
    projectType: "Architecture",
    description:
      "The pallet storage system was similar to a high-bay warehouse. Its purpose was to move a robot to deliver parts to a machine tool that builds pieces, like car motor pieces.",
    technologies:
      "C#, .NET Framework, Windows Services, WPF, WebAPI, SignalR, Entity Framework, SQLite, Domain Driven Design (DDD)",
    role: "Architect, Tech Lead",
    imageSrc: ProjectPalletStorageControlSystemImage,
  },
  {
    projectName: "Online Backup Web Portal",
    projectType: "Architecture, Code Review",
    description:
      "The portal was a powerful management tool allowing business managers to manage and protect their network (servers, group of servers or normal computers).",
    technologies: "C#, .NET Framework, Silverlight, WCF, MVVM",
    role: "Architecture Advisor, Code Reviewer",
    imageSrc: ProjectOnlineBackupWebPortalImage,
  },
  {
    projectName: "Parental Control",
    projectType: "Desktop App",
    description:
      "An app used by parents to restrict children’s computer access. Main modules: Time Filter – when to use computer, App Filter – what apps run, and Web Filter – what sites are allowed.",
    technologies:
      "C#, .NET Framework, Windows Forms, Entity Framework, SQL Server Compact, Windows Services, System Event Notification Services (SENS), Windows User Accounts, Web-Desktop Synch Engine, Multithreading, Windows Installer XML (WIX)",
    role: "Architect, Team Lead, Lead Developer",
    imageSrc: ProjectParentalControlImage,
  },
  {
    projectName: "Security Management Console",
    projectType: "Web, Desktop Apps",
    description:
      "App for managing vulnerabilities, attacks, spyware and remediation. App had modules like: central web app, remote scan agents (anti-virus, vulnerabilities, etc.), and an app bus.",
    technologies:
      "C#, .NET Framework, ASP.NET, AJAX, XML, Windows Services, C++, RPC, Multithreading, SQL Server, NHibernate, Action Scrip / Flex, Excel VB Script, DOS Batch Scripts",
    role: "Team Lead, Lead Developer, DBA Support",
    imageSrc: ProjectSecurityManagementConsoleImage,
  },
  {
    projectName: "Construction Company Management",
    projectType: "Web App",
    description:
      "Front-end website with portfolio, images, markets, etc. and back-end user role based administration site for company resources and tasks planning.",
    technologies:
      "C#, .NET Framework, ASP.NET, HTML, SQL Server, Microsoft VISIO for Database Generation and Diagrams Design",
    role: "Architect, Team Lead, Lead Developer",
    imageSrc: ProjectConstructionCompanyManagementImage,
  },
  {
    projectName: "Analytics App",
    projectType: "Web App",
    description:
      "The website gathered website activity data from many providers like: Atlas, Google Analytics, KeywordMax, etc. and presented different statistical charts.",
    technologies:
      "C#, .NET Framework, ASP.NET, SQL Server, SSIS (Microsoft SQL Server Integration Services), Microsoft Exchange SSIS Custom Created Component, Fusion Charts Flash Components",
    role: "Architect, Team Lead, Lead Developer",
    imageSrc: ProjectAnalyticsAppImage,
  },
  {
    projectName: "E-commerce for Pool Toys, Candles",
    projectType: "Web Apps",
    description:
      "Pool toys and candles e-commerce websites based on a BVCommerce framework extended to add features like: FedEx shipping and notifications, shipment cost estimation, etc.",
    technologies:
      "C#, VB.NET, .NET Framework, ASP.NET, FedEx Web Services (including payment), SQL Server",
    role: "Team Lead, Lead Developer",
    imageSrc: ProjectEcommerceForPoolToysCandlesImage,
  },
  {
    projectName: "Online Tickets",
    projectType: "Web App",
    description:
      "Website for people to buy tickets for events like sports, theater, concert, etc. Events were filtered by date, location, category, etc. with venue map for the position of ticket seats.",
    technologies:
      "C#, .NET Framework, ASP.NET, AJAX, SQL Server, Umbraco CMS, XML, Event Inventory (EI) Web Services, Data Sync, Windows Services",
    role: "Architect, Team Lead, Lead Developer",
    imageSrc: ProjectOnlineTicketsImage,
  },
  {
    projectName: "Telecom Flex Info Window",
    projectType: "Web App",
    description:
      "Developed and integrated a rich flex info window into a software solution providing network analytics combined with business intelligence for a telecom company.",
    technologies:
      "C#, .NET Framework, ASP.NET, JavaScript, Action Script, Adobe Flex, XML",
    role: "Team Lead, Developer",
    imageSrc: ProjectFlexInfoWindowImage,
  },
  {
    projectName: "Massage Invitation",
    projectType: "Web App",
    description:
      "Presentation and e-commerce website that offered different types of massages. The website also allowed purchases of electronic gift cards, via a new wizard that was added.",
    technologies:
      "C#, .NET Framework, ASP.NET, HTML, JavaScript, AJAX, SQL Server, Umbraco CMS, XML, PDF Filling, Element PS Web Services (payment)",
    role: "Team Lead, Lead Developer",
    imageSrc: ProjectMassageInvitationImage,
  },
  {
    projectName: "Athlete Academy",
    projectType: "Web App",
    description:
      "Website that allowed players to begin tracking growth and performance throughout their primary school years enabling them to provide coaches with a new level of information.",
    technologies:
      "C#, .NET Framework, ASP.NET, HTML, AJAX, SQL Server, ORM (.NetTiers), Umbraco CMS, XML, CSV",
    role: "Architect, Team Lead, Lead Developer",
    imageSrc: ProjectAthleteAcademyImage,
  },
  {
    projectName: "Business Map Manager",
    projectType: "Web App",
    description:
      "GIS oriented app that provided a large area of mapping features with widgets for switching map types (Map/Hybrid/Aerial), find a location, add points of interest, draw on map, etc.",
    technologies:
      "Action Script, Adobe Flex, MXML, Cairngorm Architecture, ESRI ArcWeb Services, ESRI AWX2 Framework, XML, MySQL",
    role: "Architect, Team Lead, Lead Developer",
    imageSrc: ProjectBusinessMapManagerImage,
  },
  {
    projectName: "3D Business Scenario Modelling",
    projectType: "Desktop App",
    description:
      "A tool that helped small business owners to improve business using proprietary graphical representation to model the process, stimulus, business events, in a 3D intuitive presentation.",
    technologies: "C#, .NET Framework 3.0 Beta, WPF, Expression Blend",
    role: "Architect, Team Lead, Lead Developer",
    imageSrc: Project3DBusinessScenarioModellingImage,
  },
  {
    projectName: "Map Route Planner",
    projectType: "Web, Desktop Apps",
    description:
      "The app was an internet mapping solution to add mapping capabilities to websites. Users could create custom maps with symbols for places and generate driving directions.",
    technologies:
      "C#, AJAX, FLASH, ASP, ASP.NET, VB.NET, JScript, VBScript JSP, Tomcat, IIS, C++, ATL, MFC, ActiveX, COM, Windows Services, Networking, XML, GDI+",
    role: "Team Lead, Lead Developer",
    imageSrc: ProjectMapRoutePlannerImage,
  },
  {
    projectName: "Industrial Photo Printing",
    projectType: "Desktop App",
    description:
      "A software suite intended for industrial large format printing and photo editing, for both Mac and Windows, with PDF editor and software to drive printing and cut devices.",
    technologies: "C++, ATL, MFC, QT, COM/ActiveX, Multithreading, Networking",
    role: "Developer",
    imageSrc: ProjectIndustrialPhotoPrintingImage,
  },
];

function Work(prop: any) {
  return (
    <TitleContent
      id="work"
      title="Work"
      subtitle="Some of the projects I worked on"
      containerClassName="work-mf sect-pt6 route"
    >
      {projects.map((project) => (
        <WorkCard
          key={project.projectName}
          projectName={project.projectName}
          projectType={project.projectType}
          description={project.description}
          technologies={project.technologies}
          role={project.role}
          imageSrc={project.imageSrc}
          techImageSrc={project.techImageSrc}
          link={project.link}
        />
      ))}
    </TitleContent>
  );
}

export default Work;
