import React from "react";

const TitleContent: React.FC<{
  id: string;
  containerClassName: string;
  title: string;
  subtitle: string;
  children: any;
  showBox?: boolean;
}> = ({
  id,
  containerClassName,
  title,
  subtitle,
  children,
  showBox = false,
}) => {
  const boxClassName = showBox ? "box-shadow-full" : "";
  return (
    <section id={id} className={containerClassName}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="title-box text-center">
              <h3 className="title-a">{title}</h3>
              <p className="subtitle-a">{subtitle}</p>
            </div>
          </div>
        </div>
        <div className={boxClassName}>
          <div className="row">{children}</div>
        </div>
      </div>
    </section>
  );
};

export default TitleContent;
