import { ApiResponse } from "apisauce";
import Post from "../models/post";
import httpService from "./httpService";

export default class PostService {
  private readonly baseEndpoint: string = "/posts";

  getPosts() {
    return httpService.get<Post[]>(`${this.baseEndpoint}/getPosts`);
  }

  getPublicPosts(): Promise<ApiResponse<Post[]>> {
    return httpService.get<Post[]>(`${this.baseEndpoint}/getPublicPosts`);
  }

  // sample return type from apisauce
  getPostById(idPost: number): Promise<ApiResponse<Post>> {
    return httpService.get(`${this.baseEndpoint}/getPostById?idPost=${idPost}`);
  }

  getPublicPostByShortLink(shortLink: string) {
    return httpService.get<Post>(
      `${this.baseEndpoint}/getPublicPostByShortLink?shortLink=${shortLink}`
    );
  }

  getPostByShortLink(shortLink: string) {
    return httpService.get<Post>(
      `${this.baseEndpoint}/getPostByShortLink?shortLink=${shortLink}`
    );
  }

  createPost(post: Post) {
    return httpService.post(`${this.baseEndpoint}/createPost`, post);
  }

  updatePost(post: Post) {
    return httpService.post(`${this.baseEndpoint}/updatePost`, post);
  }

  deletePost(idPost: number) {
    return httpService.post(`${this.baseEndpoint}/deletePost`, idPost);
  }

  updatePostImage(formData: any) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpService.post(`${this.baseEndpoint}/updatePostImage`, formData, {
      headers,
    });
  }

  getPublicPostComments(idPost: number) {
    return httpService.get(
      `${this.baseEndpoint}/getPublicPostComments?idPost=${idPost}`
    );
  }

  // function
  createPublicPostComment(idPost: number, comment: string) {
    return httpService.post(`${this.baseEndpoint}/createPublicPostComment`, {
      idPost,
      comment,
    });
  }

  // property
  deletePostComment = (idPostComment: number) => {
    return httpService.post(
      `${this.baseEndpoint}/deletePostComment`,
      idPostComment
    );
  };
}
