import BoxContent from "../common/BoxContent";
import ChartMessages from "./dashboard/ChartMessages";
import ChartPosts from "./dashboard/ChartPosts";

function Admin(props: any) {
  return (
    <BoxContent id="admin" containerClassName="" useFullWidth={true}>
      <div className="row">
        <div className="col-sm-6">
          <h1 className="admin-page-title">Welcome Administrator</h1>
        </div>
      </div>
      <div>
        <h4>Posts Comments #</h4>
        <ChartPosts />
      </div>
      <div style={{ marginTop: 50 }}>
        <h4>Chat Messages / Day</h4>
        <ChartMessages />
      </div>
    </BoxContent>
  );
}

export default Admin;
