import React, { useEffect } from "react";
import identityService from "../services/identityService";

function Logout() {
  useEffect(() => {
    identityService.logout();
    window.location.href = "/";
  }, []);
  return <div></div>;
}

export default Logout;
