import * as signalR from "@microsoft/signalr";
import { getBaseUrl } from "./httpService";
import identityService from "./identityService";
import httpService from "./httpService";

const chatHubEndpoint = "/chathub";
const receiveMessageMethod = "receiveMessage";
const sendMessageMethod = "sendMessage";

const baseEndpoint = "/chats";
const getMainChatMessagesEndpoint = "/getMainChatMessages";
const deleteChatMessageEndpoint = "/deleteChatMessage";
//
// const connection = new signalR.HubConnectionBuilder()
//   .withUrl(getBaseUrl() + chatHubEndpoint)
//   .build();

let authToken = identityService.getToken();
let accessToken: string = authToken ? authToken : "";

const connection = new signalR.HubConnectionBuilder()
  .withUrl(getBaseUrl() + chatHubEndpoint, {
    accessTokenFactory: () => accessToken,
  })
  .build();

let onMessageReceivedCallback: any;

connection.on(
  receiveMessageMethod,
  (name, username, profileImageUrl, message, messageDate) => {
    onMessageReceivedCallback(
      name,
      username,
      profileImageUrl,
      message,
      messageDate
    );
  }
);

const onMessageReceived = (callback: any) => {
  onMessageReceivedCallback = callback;
};

const connect = async () => {
  if (connection.state === signalR.HubConnectionState.Disconnected) {
    await connection.start().catch((err) => alert(err));
  }
};

const sendMessage = async (message: string) => {
  await connection.send(sendMessageMethod, message).catch((err) => alert(err));
};

const getMainChatMessages = () => {
  return httpService.get(baseEndpoint + getMainChatMessagesEndpoint);
};

const deleteChatMessage = (idChatMessage: number) => {
  return httpService.post(
    baseEndpoint + deleteChatMessageEndpoint,
    idChatMessage
  );
};

const exportObject = {
  onMessageReceived,
  connect,
  sendMessage,
  getMainChatMessages,
  deleteChatMessage,
};

export default exportObject;
